import usFlag from '../assets/images/flags/us.jpg'
import poland from '../assets/images/flags/pl.png'

// import poland from
const languages = {
  pl: {
    label: 'Poland',
    flag: poland
  },
  eng: {
    label: 'English',
    flag: usFlag
  }
}

export default languages
