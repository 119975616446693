import React from 'react'
import styles from './Dashboard.module.css'
import { Button, Card, CardBody, Col, Row } from 'reactstrap'
import profileImg from '../../assets/images/profile-img.png'
import { getDevicesValue, getKeysValue, getSetting, getUsername } from '../../Selector/selector'
import { useSelector } from 'react-redux'
import { Statistic } from 'antd'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const DashboardInformation = () => {
  const {t} = useTranslation()
  const devicesValue = useSelector(getDevicesValue)
  console.log(devicesValue)
  const keysValue = useSelector(getKeysValue)
  const username = useSelector(getUsername)
  const settingData = useSelector(getSetting)
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h4 className={styles.dashboardText}>{settingData.name}</h4>
                <h5 className={styles.dashboardText}>{settingData.description}</h5>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <Button className={styles.dashboardButton}>
                <Link to={'/settings'}>
                  <span className={styles.dashboardButtonWrapper}>
                    <i className="bx bx-wrench font-size-16 align-middle me-1" />
                    {t('Settings')}
                  </span>
                </Link>
              </Button>
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <h5 className="font-size-15 text-truncate mt-5">{username}</h5>
            </Col>
            <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <Statistic title={t('Devices')} value={devicesValue!.all ? devicesValue!.all : 0} />
                  </Col>
                  <Col xs="6">
                    <Statistic title={t('Keys')} value={keysValue!.all ?keysValue!.all : 0 } />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default DashboardInformation
