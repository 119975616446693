/// <reference types="google.maps" />
import React, { Dispatch, useEffect, useState } from 'react'
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api'
import { DeviceZoom } from '../../projectComponents/pages/Device/type/DeviceType'

interface PropsI {
  devices: DeviceZoom[]
  setRectangleLocation: Dispatch<any>
  center: {
    lat: number
    lng: number
  }
}

export const DevicesMap: React.FC<PropsI> = ({ devices, setRectangleLocation, center }) => {
  const [map, setMap] = useState<google.maps.Map>()
  const [markers, setMarkers] = useState<any>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [infoWindow, setInfoWindow] = useState<any>()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY as string
  })

  useEffect(() => {
    setMarkers(renderMarkers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices])

  // Create an array of alphabetical characters used to label the markers.
  const labels: any = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const renderMarkers = () => {
    return (
      <MarkerClusterer
        imagePath={'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'}
      >
        {(clusterer) => {
          return devices.map((el: any, index: any) => (
            <Marker
              label={el.address[index % labels]}
              key={`el_#${index}`}
              position={{
                lat: el.lat,
                lng: el.lng
              }}
              clusterer={clusterer}
            />
          ))
        }}
      </MarkerClusterer>
    )
  }

  const updateDevices = () => {
    if (!map) return

    const bounds = map.getBounds() as google.maps.LatLngBounds
    let ne = bounds.getNorthEast()
    let sw = bounds.getSouthWest()

    const { lat: neLat, lng: neLng } = ne.toJSON()
    const { lat: swLat, lng: swLng } = sw.toJSON()

    return setRectangleLocation({ neLat, neLng, swLat, swLng })
  }

  const onLoad = (map: google.maps.Map) => {
    setMap(map)

    setTimeout(() => {
      map.panTo(center)
    }, 1)
  }

  return isLoaded ? (
    <GoogleMap
      id="devicesMap"
      mapContainerStyle={{ width: '100%', height: '100%', position: 'relative' }}
      zoom={10}
      center={center}
      onDragEnd={updateDevices}
      onZoomChanged={updateDevices}
      onLoad={onLoad}
    >
      {markers}
      {infoWindow &&
        infoWindow.open({
          anchor: markers,
          map
        })}
    </GoogleMap>
  ) : (
    <></>
  )
}
