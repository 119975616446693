import React from 'react'
import { Card, CardBody, Media } from 'reactstrap'
import img1 from '../../../../assets/image/og-logo.png'
import { timeFromNow } from '../../../../utils/supportFunctions'
import { DevicesInfo } from '../type/DeviceType'
import styles from '../Device.module.css'
import { Col, Row, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

interface PropsI {
  project: DevicesInfo
}

export const DeviceDetail: React.FC<PropsI> = ({ project }) => {
  const { details } = project
  const {t} = useTranslation()
  return (
    <Card style={{ height: '100%' }}>
      <CardBody>
        <Row>
          <Col span={20}>
            <Media className={'mt-3'}>
              {details.assetUrl ? (
                <img src={details.assetUrl} alt="" className="avatar-sm me-4" />
              ) : (
                <img src={img1} alt="" className="avatar-sm me-4" />
              )}
              <Media className="overflow-hidden" body>
                <h3 className="text-truncate">{project.name}</h3>
                <p className="text-muted">{project.description}</p>
              </Media>
            </Media>
            {details.deviceModel ? <h5 className="font-size-15 mt-4">{t('Device Details')} :</h5> : null}
            <Media className="overflow-hidden text-muted" body>
              {details.deviceModel ? (
                <p>
                  <i className="mdi mdi-chevron-right text-primary me-1 " />
                  {t('Device model')}:
                  <span className={styles.textInformation}>{details.deviceModel}</span>
                </p>
              ) : null}
              {details.deviceType ? (
                <p>
                  <i className="mdi mdi-chevron-right text-primary me-1 " />
                  {t('Device type')}:
                  <span className={styles.textInformation}>{details.deviceType}</span>
                </p>
              ) : null}
              {details.additionalDescription ? (
                <p style={{ display: 'flex' }}>
                  <i className="mdi mdi-chevron-right text-primary me-1 " />
                  {t('Additional description')}:
                  <span style={{ color: 'var(--textColor)' }}>
                    <Tooltip title={details.additionalDescription}>{details.additionalDescription}</Tooltip>
                  </span>
                </p>
              ) : null}
              {details.contactInfo ? (
                <p>
                  <i className="mdi mdi-chevron-right text-primary me-1 " />
                  {t('Contacts')}:
                  <span className={styles.textInformation}>{details.contactInfo}</span>
                </p>
              ) : null}
              {details.url ? (
                <p>
                  <i className="mdi mdi-chevron-right text-primary me-1 " />{' '}
                  <a style={{ color: '#878a9c' }} href={details.url}>
                    {t('Url')}: <span className={styles.textInformation}>{details.url}</span>
                  </a>
                </p>
              ) : null}
            </Media>
          </Col>

          <Col style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar me-1 text-primary" />
                {t('Created Date')}
              </h5>
              <p className="text-muted mb-0">{timeFromNow(project.createdAt)}</p>
            </div>

            <div className="mt-4">
              <h5 className="font-size-14">
                <i className="bx bx-calendar-check me-1 text-primary" />
                {t('Updated Date')}
              </h5>
              <p className="text-muted mb-0">{timeFromNow(project.updatedAt)}</p>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
