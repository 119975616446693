import { State } from '../Redux/typeReducer/State'

export const getLoading = (state: State) => {
  return state.AuthReducer.loading
}

export const getUsername = (state: State) => {
  return state.AuthReducer.username
}

export const getBalance = (state: State) => {
  return state.AuthReducer.balance
}

export const getWhitelistedSupplier = (state: State) => {
  return state.AuthReducer.whitelistedSupplier
}

export const getStatusLoadingDevices = (state: State) => {
  return state.devicesReducer.statusLoadingDevices
}

export const getScript = (state: State) => {
  return state.AuthReducer.config.script
}

export const getSupplierAddress = (state: State) => {
  return state.AuthReducer.config.address
}
export const getToken = (state: State) => {
  return state.AuthReducer.accessToken
}

export const getSetting = (state: State) => {
  return state.AuthReducer.config
}

export const getLatData = (state: State) => {
  return state.devicesReducer.mapCenter.lat
}

export const getLngData = (state: State) => {
  return state.devicesReducer.mapCenter.lng
}

export const getLat = (state: State) => {
  return state.devicesReducer.mapCenter.lat
}

export const getLng = (state: State) => {
  return state.devicesReducer.mapCenter.lng
}

export const getDevicesList = (state: State) => {
  return state.devicesReducer.devicesList
}

export const getAddress = (state: State) => {
  return state.devicesReducer.address
}

export const getKeysList = (state: State) => {
  return state.keysReducer.keysList
}

export const getAssetId = (state: State) => {
  return state.keysReducer.assetId
}

export const getDevicesValue = (state: State) => {
  return state.devicesReducer.devicesValue
}
export const getKeysValue = (state: State) => {
  return state.keysReducer.keysValue
}

//layout
export const getLeftSideBarType = (state: State) => {
  return state.Layout.leftSideBarType
}

export const getLeftSideBarTheme = (state: State) => {
  return state.Layout.leftSideBarTheme
}

export const getIsMobile = (state: State) => {
  return state.Layout.isMobile
}

export const getLeftMenu = (state: State) => {
  return state.Layout.leftMenu
}

export const getShowRightSidebar = (state: State) => {
  return state.Layout.showRightSidebar
}

export const getLayout = (state: State) => {
  return state.Layout
}
