import React, { Dispatch, SetStateAction, useState } from 'react'
import { Tag, Tooltip } from 'antd'
import { Card, CardBody } from 'reactstrap'
import { KeysListType } from '../../../../Redux/typeReducer/KeysType'
import { GenerateKey } from '../../../Modal/GenerateKey'
import { useDispatch, useSelector } from 'react-redux'
import { addKeyToDevice, burnedKey, removeKeyFromDevice } from '../../../../Redux/Keys/KeysReducer'
import { actions } from '../../../../Redux/Keys/keys.actions'
import { TransferKey } from '../../../Modal/TransferKey'
import { getSupplierAddress } from '../../../../Selector/selector'
import { KeysListActions } from './KeysListActions'
import moment from 'moment'
import { ParamsKeys } from '../../../../query/query'
import { KeyListMainHeader } from './KeyListMainHeader'
import { KeyListMainTable } from './KeyListMainTable'
import { KeyStatistics } from '../KeysContainer'
import { useTranslation } from 'react-i18next'

interface PropsI {
  setParamsKeys: Dispatch<SetStateAction<ParamsKeys>>
  data: KeysListType[]
  paramsKeys: ParamsKeys
  loading: boolean
  deviceGenerateKey?: string
  keysStatistic: KeyStatistics
}

export const KeysListMainContainer: React.FC<PropsI> = ({
  paramsKeys,
  data,
  deviceGenerateKey,
  loading,
  setParamsKeys,
  keysStatistic
}) => {
  const {t} = useTranslation()
  let supplierAddress = useSelector(getSupplierAddress)
  const dispatch = useDispatch()

  const [generateKey, setGenerateKey] = useState(false)

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  const togGenerateKey = () => {
    setGenerateKey(!generateKey)
    removeBodyCss()
  }

  const [isTransferKeyModalVisible, setIsTransferKeyModalVisible] = useState(false)

  const showTransferKeyModal = (assetId: string) => {
    setIsTransferKeyModalVisible(!isTransferKeyModalVisible)
    dispatch(actions.setAssetIdKey(assetId))
  }
  const togTransferKey = () => {
    setIsTransferKeyModalVisible(!isTransferKeyModalVisible)
    removeBodyCss()
  }

  const handleAddKeyToDevice = (deviceAddress: string, assetId: string) => {
    dispatch(addKeyToDevice(deviceAddress, assetId, supplierAddress))
  }

  const handleDeleteKeyFromDevice = (deviceAddress: string, assetId: string) => {
    dispatch(removeKeyFromDevice(deviceAddress, assetId, supplierAddress))
  }

  const burnedKeyFromList = (assetId: string) => {
    dispatch(burnedKey(assetId, supplierAddress))
  }

  const ColumnsKeysList = [
    {
      title: t('Key name'),
      dataIndex: 'name',
      key: 'name',
      width: 100,
      align: 'center' as 'center',
      render: (name: string) => <span style={{ color: 'var(--textColor)' }}>{name}</span>
    },
    {
      title: t('Owner'),
      dataIndex: 'owner',
      key: 'owner',
      width: 100,
      align: 'center' as 'center',
      render: (owner: string) => {
        const color = owner === supplierAddress ? 'var(--orangeOwner)' : 'var(--otherOwner)'
        const me = owner === supplierAddress ? 'Orange' : 'Other'

        return (
          <Tag color={color} key={owner} style={{ alignItems: 'center' }}>
            <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_OWNER_URL_EXPLORER}${owner}`}>
              <Tooltip placement="topRight" title={owner}>
                {me.toUpperCase()}
              </Tooltip>
            </a>
          </Tag>
        )
      }
    },
    {
      title: t('AssetId'),
      dataIndex: 'assetId',
      key: 'assetId',
      align: 'center' as 'center',
      render: (assetId: string) => (
        <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_ASSET_ID_URL_EXPLORER}${assetId}`}>
          <Tooltip placement="topRight" title={assetId}>
            {assetId}
          </Tooltip>
        </a>
      )
    },
    {
      title: t('Device name'),
      dataIndex: 'deviceName',
      key: 'deviceName',
      align: 'center' as 'center',
      render: (deviceName: string) => <span style={{ color: 'var(--textColor)' }}>{deviceName}</span>
    },
    {
      title: t('Created'),
      dataIndex: 'issueTimestamp',
      key: 'issueTimestamp',
      width: 200,
      align: 'center' as 'center',
      render: (issueTimestamp: string) => {
        const timeAgo = moment(issueTimestamp).format('YYYY-MM-DD HH:mm:ss')
        return (
          <Tag key={timeAgo} color={'green'}>
            {timeAgo}
          </Tag>
        )
      }
    },
    {
      title: t('Valid to'),
      dataIndex: 'validTo',
      key: 'validTo',
      width: 200,
      align: 'center' as 'center',
      render: (validTo: number) => {
        const timeAgo = validTo - Date.now()
        const convertToDays = Math.floor(timeAgo / 8.64e7)

        let color
        if (convertToDays > Number(process.env.REACT_APP_DATE_TIME_MONTH)) {
          color = 'green'
        } else if (
          convertToDays < Number(process.env.REACT_APP_DATE_TIME_MONTH) &&
          convertToDays > Number(process.env.REACT_APP_DATE_TIME_WEEK)
        ) {
          color = 'orange'
        } else if (convertToDays < Number(process.env.REACT_APP_DATE_TIME_WEEK)) {
          color = 'red'
        }
        return (
          <Tag key={convertToDays} color={color}>
            {moment(validTo).format('YYYY-MM-DD HH:mm:ss')}
          </Tag>
        )
      }
    },
    {
      title: t('Action'),
      key: 'data',
      width: 200,
      align: 'center' as 'center',
      ellipsis: true,
      render: (data: any) => {
        return (
          <KeysListActions
            handleDeleteKeyFromDevice={() => {
              handleDeleteKeyFromDevice(data.device, data.assetId)
            }}
            handleAddKeyToDevice={() => handleAddKeyToDevice(data.device, data.assetId)}
            showTransferKeyModal={showTransferKeyModal}
            burnedKey={() => burnedKeyFromList(data.assetId)}
            device={data.device}
            assetId={data.assetId}
            owner={data.owner}
            whitelisted={data.whitelisted}
          />
        )
      }
    }
  ]

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <KeyListMainHeader togGenerateKey={togGenerateKey} />
          <KeyListMainTable
            keysStatistic={keysStatistic}
            setParamsKeys={setParamsKeys}
            paramsKeys={paramsKeys}
            ColumnsKeysList={ColumnsKeysList}
            loading={loading}
            data={data}
            supplierAddress={supplierAddress}
          />
        </CardBody>
      </Card>

      {generateKey && (
        <GenerateKey
          deviceGenerateKey={deviceGenerateKey}
          togModal={togGenerateKey}
          generateModalCenter={generateKey}
          setGenerateModalCenter={setGenerateKey}
        />
      )}

      {isTransferKeyModalVisible && (
        <TransferKey
          togModal={togTransferKey}
          isTransferKeyModalVisible={isTransferKeyModalVisible}
          setIsTransferKeyModalVisible={setIsTransferKeyModalVisible}
        />
      )}
    </React.Fragment>
  )
}
