import React, { useState } from 'react'
import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { MenuContainer } from './DropdownMenu'
import { useTranslation } from 'react-i18next'

interface PropsI {
  handlerValueOwner: (value: string) => void
  handlerKeysOwnerValue: (value: string) => void
  handlerSupplierValue: (value: string) => void
}

export const DropDownFilter: React.FC<PropsI> = ({
  handlerValueOwner,
  handlerKeysOwnerValue,
  handlerSupplierValue
}) => {
  const {t} = useTranslation()
  const [visible, setVisible] = useState(false)

  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown
          overlayStyle={{ width: '500px' }}
          overlay={
            <MenuContainer
              handlerValueOwner={handlerValueOwner}
              handlerKeysOwnerValue={handlerKeysOwnerValue}
              handlerSupplierValue={handlerSupplierValue}
            />
          }
          placement="bottomCenter"
          arrow={true}
          visible={visible}
          trigger={['click']}
          onVisibleChange={(visible) => setVisible(visible)}
        >
          <Button>
            {t('Address')} <DownOutlined />
          </Button>
        </Dropdown>
      </Space>
    </Space>
  )
}
