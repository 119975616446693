import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'antd'
import { KeyFilterContainer } from '../FilterHelper/KeyFilterContainer'
import { FireOutlined } from '@ant-design/icons'
import { ParamsKeys } from '../../../../query/query'
import { KeyStatistics } from '../KeysContainer'
import { useTranslation } from 'react-i18next'

interface PropsI {
  keysStatistic: KeyStatistics
  paramsKeys: ParamsKeys
  setParamsKeys: Dispatch<SetStateAction<ParamsKeys>>
}

export const KeyListTitle: React.FC<PropsI> = ({ paramsKeys, setParamsKeys, keysStatistic }) => {
  console.log(keysStatistic)
  const { t } = useTranslation()
  return (
    <Row className={'mb-2 me-2'} align={'middle'}>
      <Col span={21}>
        <KeyFilterContainer params={paramsKeys} setParams={setParamsKeys} />
      </Col>
      <Col span={3}>
        <h5 style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
          <FireOutlined style={{ color: 'red', marginRight: '5px' }} /> {t('Burned')}: {keysStatistic.burned_true} /
          {keysStatistic.all}
        </h5>
      </Col>
    </Row>
  )
}
