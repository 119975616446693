import React from 'react'
import SimpleBar from 'simplebar-react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const SidebarContent = (props: any) => {
  const {t} = useTranslation()
  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{t('Menu')}</li>
            <li>
              <Link className="waves-effect" to="/dashboard">
                <i className="bx bx-home-circle" />
                <span>{t('Dashboard')}</span>
              </Link>
            </li>
            <li>
              <Link to="/devices">
                <i className="bx bx-devices" />
                <span>{t('Devices')}</span>
              </Link>
            </li>

            <li>
              <Link to="/keys">
                <i className="bx bx-key" />
                <span>{t('Keys')}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}
