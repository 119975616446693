import React, { useEffect, useState } from 'react'
import {
  CreateDevice,
  DetailsList,
  DeviceCustom,
  DeviceLocation,
  DeviceMain,
  DevicePhysicalAddress
} from '../type/DeviceType'
import { AddDeviceFormAddress } from '../DeviceFormElement/AddDeviceFormAddress'
import { Col, Row } from 'antd'
import { Button, Container } from 'reactstrap'
import Breadcrumbs from '../../../../utils/Breadcrumb/Breadcrumb2'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { addDevices } from '../../../../Redux/Device/DevicesReducer'
import { parseForm } from '../DeviceSupportFunc/supportDeviceForm'
import { AddDeviceFormCustom } from '../DeviceFormElement/AddDeviceFormCustom'
import { AddDeviceFormDetails } from '../DeviceFormElement/AddDeviceFormDetails'
import { AddDeviceFormLocation } from '../DeviceFormElement/AddDeviceFormLocation'
import AddDeviceFormMain from '../DeviceFormElement/AddDeviceFormMain'
import { getLat, getLng, getSupplierAddress } from '../../../../Selector/selector'
import { Link, useHistory } from 'react-router-dom'
import styles from '../Device.module.css'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const AddDeviceForm = () => {
  const supplierAddress = useSelector(getSupplierAddress)
  const {t} = useTranslation()
  const lat = useSelector(getLat)
  const lng = useSelector(getLng)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setDeviceLocation({ ...deviceLocation, lng: lng, lat: lat })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng])

  const [deviceMain, setDeviceMain] = useState<DeviceMain>({
    name: '',
    description: '',
    deviceType: 'car barrier' || 'human barrier' || 'elevator' || 'human' || 'mobile' || 'other',
    active: true,
    connected: true,
    visible: true
  })

  const [deviceDetails, setDeviceDetails] = useState<DetailsList>({
    deviceModel: '',
    assetUrl: '',
    additionalDescription: '',
    url: '',
    contactInfo: ''
  })

  const [deviceLocation, setDeviceLocation] = useState<DeviceLocation>({
    lat: lat,
    lng: lng,
    alt: 0
  })

  const [devicePhysicalAddress, setDevicePhysicalAddress] = useState<DevicePhysicalAddress>({
    addressLine1: '',
    addressLine2: '',
    city: '',
    postcode: '',
    state: '',
    country: '',
    number: '',
    floor: ''
  })

  const [deviceCustom, setDeviceCustom] = useState<DeviceCustom[]>([
    {
      key: '',
      value: ''
    }
  ])

  const resetData = () => {
    setDeviceMain({
      name: '',
      description: '',
      deviceType: 'car barrier' || 'human barrier' || 'elevator' || 'human' || 'mobile' || 'other',
      active: true,
      connected: true,
      visible: true
    })
    setDeviceDetails((prevState) => ({
      deviceModel: '',
      additionalDescription: '',
      assetUrl: '',
      url: '',
      contactInfo: ''
    }))
    setDeviceLocation({
      lat: 0,
      lng: 0,
      alt: 0
    })
    setDevicePhysicalAddress({
      addressLine1: '',
      addressLine2: '',
      city: '',
      postcode: '',
      state: '',
      country: '',
      number: '',
      floor: ''
    })
    setDeviceCustom([
      {
        key: '',
        value: ''
      }
    ])
  }

  const initialValues: any = {
    ...deviceMain,
    ...deviceDetails,
    ...deviceLocation,
    ...devicePhysicalAddress,
    ...deviceCustom
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col span={12}>
              <Breadcrumbs title={t('Device')} breadcrumbItems={t('Add Device')} />
            </Col>
            <Col span={12}>
              <Button
                style={{ float: 'right' }}
                type="button"
                color={'primary'}
                className="btn-rounded waves-effect waves-light mb-2 me-2"
              >
                <Link to={'/devices'}>
                  <span className={styles.textButtonAdd}>
                    <ArrowLeftOutlined className={styles.iconStyle} />
                    <span> {t('Go Back')}</span>
                  </span>
                </Link>
              </Button>
            </Col>
          </Row>

          <Formik
            initialValues={initialValues}
            onSubmit={(values: CreateDevice, { setSubmitting }) => {
              dispatch(
                addDevices(
                  history,
                  parseForm(deviceMain, deviceLocation, deviceDetails, deviceCustom, devicePhysicalAddress),
                  supplierAddress
                )
              )
              setSubmitting(false)
              resetData()
            }}
          >
            <Form>
              <Row>
                <Col span={12}>
                  <AddDeviceFormMain deviceMain={deviceMain} setDeviceMain={setDeviceMain} />
                </Col>

                <Col span={12}>
                  <AddDeviceFormLocation deviceLocation={deviceLocation} setDeviceLocation={setDeviceLocation} />
                </Col>
              </Row>
              <Row className={'mt-2'}>
                <Col span={12}>
                  <AddDeviceFormDetails deviceDetails={deviceDetails} setDeviceDetails={setDeviceDetails} />
                </Col>
                <Col span={12}>
                  <AddDeviceFormAddress
                    setDevicePhysicalAddress={setDevicePhysicalAddress}
                    devicePhysicalAddress={devicePhysicalAddress}
                  />
                </Col>
              </Row>

              <Row className={'mt-2'}>
                <Col span={24}>
                  <AddDeviceFormCustom deviceCustom={deviceCustom} setDeviceCustom={setDeviceCustom} />
                </Col>
              </Row>
              <Row className={'mb-3'}>
                <Button type="submit" color="success" className="btn-rounded waves-effect waves-light">
                  {t('Create')}
                </Button>
              </Row>
            </Form>
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  )
}
