import { DeviceContainer } from '../projectComponents/pages/Device/DeviceContainer'
import { DeviceOverview } from '../projectComponents/pages/Device/DevicesOverview/DeviceOverview'
import { SetupFormScreen } from '../projectComponents/pages/Setting/ContactsProfile/SetupFormScreen'
import { AddDeviceForm } from '../projectComponents/pages/Device/AddDevice/AddDeviceForm'
import ContactsProfile from '../projectComponents/pages/Setting/ContactsProfile/ContactsProfile'
import { EditDeviceFormContainer } from '../projectComponents/pages/Device/EditDevice/EditFormContainer'
import { KeysContainer } from '../projectComponents/pages/Keys/KeysContainer'
import Dashboard from '../projectComponents/Dashboard/Dashboard'
import { Login } from '../projectComponents/pages/Authentication/Login'

export const Routes: {
  id: number
  path: string
  Component: any
  requireToken: boolean
  requireScript: boolean
}[] = [
  {
    id: 1,
    path: '/login',
    Component: Login,
    requireToken: false,
    requireScript: false
  },
  {
    id: 2,
    path: '/utils/setup',
    Component: SetupFormScreen,
    requireToken: true,
    requireScript: false
  },
  {
    id: 3,
    path: '/devices',
    Component: DeviceContainer,
    requireToken: true,
    requireScript: true
  },
  {
    id: 5,
    path: '/device/:address',
    Component: DeviceOverview,
    requireToken: true,
    requireScript: true
  },
  {
    id: 6,
    path: '/keys',
    Component: KeysContainer,
    requireToken: true,
    requireScript: true
  },
  {
    id: 7,
    path: '/dashboard',
    Component: Dashboard,
    requireToken: true,
    requireScript: true
  },
  // {
  //   id: 8,
  //   path: '/page404',
  //   Component: Pages404,
  //   access_token: null,
  //   isAuth: false
  // },
  // {
  //   id: 9,
  //   path: '/page404',
  //   Component: Pages404,
  //   access_token: '',
  //   isAuth: true
  // },
  {
    id: 10,
    path: '/add-device',
    Component: AddDeviceForm,
    requireToken: true,
    requireScript: true
  },
  {
    id: 12,
    path: '/edit-device/:address',
    Component: EditDeviceFormContainer,
    requireToken: true,
    requireScript: true
  },
  {
    id: 13,
    path: '/settings',
    Component: ContactsProfile,
    requireToken: true,
    requireScript: true
  }
]
