import React from 'react'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { getAddress, getLat, getLng } from '../../Selector/selector'
import { devicesActions } from '../../Redux/Device/devices.actions'
import SpinnerContainer from '../Spiner/SpinnerContainer'
import styles from './GoogleM.module.css'
export const MapContainer = () => {
  const address = useSelector(getAddress)
  const lat = useSelector(getLat)
  const lng = useSelector(getLng)
  const dispatch = useDispatch()

  const handleChange = (address) => {
    dispatch(devicesActions.setGoogleMapsAddress(address))
  }
  const google = window.google

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        return getLatLng(results[0])
      })
      .then((latLng) => {
        // update center state
        const { lat, lng } = latLng
        dispatch(devicesActions.setLat(lat))
        dispatch(devicesActions.setLng(lng))
      })
      .catch((error) => console.error('Error', error))
  }

  return (
    <div id="googleMaps">
      <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              className={styles.inputSearch}
              {...getInputProps({
                placeholder: 'Search Places ...'
                // className: 'location-search-input'
              })}
            />
            <div className={styles.autocompleteDropdownContainer}>
              {loading && <SpinnerContainer />}
              {suggestions.map((suggestion) => {
                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                    key={suggestion.index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

      <Map
        style={{ width: '98%', position: 'absolute' }}
        google={google}
        initialCenter={{
          lat: lat,
          lng: lng
        }}
        center={{
          lat: lat,
          lng: lng
        }}
      >
        <Marker
          position={{
            lat: lat,
            lng: lng
          }}
        />
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCaHjNAqYpHpr1lPFYCkyG9vTp4An9rg84',
  libraries: ['places']
})(MapContainer)
