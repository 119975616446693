import Swal, { SweetAlertIcon } from 'sweetalert2'

export const Toast = Swal.mixin({
  toast: true,
  position: 'bottom-right',
  showConfirmButton: false,
  timer: 2500,
  timerProgressBar: false
})

export const alertToast = (icon: SweetAlertIcon | undefined, title: string) => {
  Toast.fire({
    icon: icon,
    title: title
  })
}
