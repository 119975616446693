export enum KeysActionsType {
  SET_LIST_KEYS = 'SET_KEYS_DATA',
  SET_DEVICE_KEYS_LIST = 'SET_DEVICE_KEYS_LIST',
  ADD_KEY = 'ADD_KEY',
  DELETE_KEY = 'DELETE_KEY',
  REMOVE_KEY_FROM_WHITE_LIST = 'REMOVE_KEY_FROM_WHITE_LIST',
  SET_KEYS_VALUE = 'SET_KEYS_VALUE',
  GENERATE_NEW_KEY = 'GENERATE_NEW_KEY',
  SET_ASSETID_KEY = 'SET_ASSETID_KEY'
}
