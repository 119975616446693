import styles from '../Device.module.css'
import { Col, Space, Tooltip } from 'antd'
import { PopConfirm } from '../../../../utils/supportUtils/PopConfirm'
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import { deleteDevices } from '../../../../Redux/Device/DevicesReducer'
import { useDispatch, useSelector } from 'react-redux'
import { getSupplierAddress } from '../../../../Selector/selector'
import { useTranslation } from 'react-i18next'

interface PropsI {
  address: string
}

export const DeviceListActions: React.FC<PropsI> = ({ address }) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const supplierAddress = useSelector(getSupplierAddress)
  const history = useHistory()
  const [visibleDeletePop, setVisibleDeletePop] = useState(false)

  const setVisibleDeleteConfirm = (e: any) => {
    e.stopPropagation()
    setVisibleDeletePop(!visibleDeletePop)
  }

  const deleteDevice = () => {
    dispatch(deleteDevices(history, address, supplierAddress))
  }
  return (
    <Col style={{ display: 'flex', justifyContent: 'center' }} span={24} className={styles.actionWrapper}>
      <div className={styles.actionContainer}>
        <Space className={styles.actionContainerSpace}>
          <span onClick={(e) => setVisibleDeleteConfirm(e)} className={styles.actionDelete}>
            <Tooltip placement="bottom" title={t('Delete device')}>
              <PopConfirm
                visible={visibleDeletePop}
                icon={<DeleteOutlined />}
                title={t('Are you sure delete device?')}
                handler={() => {
                  deleteDevice()
                }}
              />
              <DeleteOutlined style={{ color: 'red', fontSize: '18px' }} />
            </Tooltip>
          </span>
        </Space>
        <Space className={styles.actionMoreInfo}>
          <span className={styles.textInfo}>
            <Tooltip placement="bottom" title={t('More information')}>
              <Link to={`/device/${address}`}>
                <InfoCircleOutlined style={{ color: 'green', fontSize: '18px' }} />
              </Link>
            </Tooltip>
          </span>
        </Space>
      </div>
    </Col>
  )
}
