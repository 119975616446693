import React from 'react'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option } = Select

const options: any = [
  { key: 1, name: 'Created At - asc', order: 'asc', orderBy: 'createdAt' },
  { key: 2, name: 'Updated At - asc', order: 'asc', orderBy: 'updatedAt' },
  { key: 3, name: 'Created At - desc', order: 'desc', orderBy: 'createdAt' },
  { key: 4, name: 'Updated At - desc', order: 'desc', orderBy: 'updatedAt' }
]

interface PropsI {
  setParams: any
  params: any
}

export const DeviceFilterSelector: React.FC<PropsI> = ({ params, setParams }) => {
  const {t} = useTranslation()
  return (
    <Select
      onChange={(value: string, option: any) => {
        const opt = options.filter((i: any) => i.name === option.children)
        setParams({ ...params, sort: { order: opt[0].order, orderBy: opt[0].orderBy } })
      }}
      style={{ width: '200px' }}
      placeholder={t('Order by date')}
      optionFilterProp="children"
    >
      {options.map((item: any) => (
        <Option key={item.key} value={`${item.orderBy}${item.order}`}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
}
