import React from 'react'
import { Row } from 'antd'
import { DevicesListMainInfo } from '../type/DeviceType'
import { DeviceListAddress } from './DeviceListAddress'

interface PropsI extends DevicesListMainInfo {}

export const DevicesList: React.FC<PropsI> = ({ address, owner }) => {
  return (
    <Row>
      <DeviceListAddress key={address} address={address} owner={owner} />
    </Row>
  )
}
