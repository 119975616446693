export enum AuthActionsType {
  SET_AUTH_USER_DATA = 'SET_AUTH_USER_DATA',
  DEL_USER_DATA = 'DEL_USER_DATA',
  SET_WHITELISTED_SUPPLIER = 'SET_WHITELISTED_SUPPLIER',
  SETUP_CONFIG = 'SETUP_CONFIG',
  SET_TOKEN = 'SET_TOKEN',
  SET_USERNAME = 'SET_USERNAME',
  SET_BALANCE = 'SET_BALANCE',
  SET_SHOW_BALANCE = 'SET_SHOW_BALANCE',
  SET_CONFIG = 'SET_CONFIG',
  CREATE_NEW_CONFIG = 'CREATE_NEW_CONFIG'
}
