import React from 'react'
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react'

export const MapToDeviceContainer = ({ lat, lng }) => {
  const google = window.google

  return (
    <div id="googleMaps">
      <Map
        mapContainerStyle={{ width: '100%', height: '100%', position: 'relative' }}
        zoom={10}
        mapTypeControl={false}
        streetViewControl={false}
        google={google}
        initialCenter={{
          lat: lat,
          lng: lng
        }}
        center={{
          lat: lat,
          lng: lng
        }}
      >
        <Marker
          position={{
            lat: lat,
            lng: lng
          }}
        />
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCaHjNAqYpHpr1lPFYCkyG9vTp4An9rg84',
  libraries: ['places']
})(MapToDeviceContainer)
