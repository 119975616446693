export enum DeviceActionsType {
  ADD_DEVICE = 'ADD_DEVICE',
  SET_DEVICE_LIST = 'SET_DEVICE_LIST',
  DELETE_DEVICE = 'DELETE_DEVICE',
  SET_DEVICES_VALUE = 'SET_DEVICES_VALUE',
  SET_EDIT_DEVICE_ADDRESS = 'SET_EDIT_DEVICE_ADDRESS',
  SET_LOADING_DEVICE = 'SET_LOADING_DEVICE',
  SET_GOOGLE_MAPS_ADDRESS = 'SET_GOOGLE_MAPS_ADDRESS',
  SET_LAT = 'SET_LAT',
  SET_LNG = 'SET_LNG'
}
