import React from 'react'
import { Col, Row, Tooltip } from 'antd'
import { handleClipboardCopy } from '../../../../utils/supportFunctions'
import { CopyOutlined } from '@ant-design/icons'
import styles from './Key.module.css'
import { useTranslation } from 'react-i18next'

interface PropsI {
  device: string
}

export const KeyListMore: React.FC<PropsI> = ({ device }) => {
  const {t} = useTranslation()
  return (
    <Row>
      <Col span={5}>
        <span className={styles.textInfoContainer} onClick={() => handleClipboardCopy(device)}>
          {t('Device address')}:
          <a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_ASSET_ID_URL_EXPLORER}${device}`}>
            <Tooltip placement="topRight" title={device}>
              {device}
            </Tooltip>
          </a>
          <CopyOutlined className={styles.copyIconStyle} />
        </span>
      </Col>
    </Row>
  )
}
