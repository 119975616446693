import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'antd'
import { InputSearch } from './InputSearch'
import { DropDownFilter } from './DropDownFilter'
import { ParamsDevice } from '../../../../query/query'
import { DevicesInfo } from '../type/DeviceType'
import { DeviceFilterSelector } from './DeviceFilterSelector'
import { DeviceStatus } from './DeviceStatus'
import { convert, validateAddress } from '../../../../utils/supportFunctions'
import { alertToast } from '../../../../utils/AlertTips'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'

interface PropsI {
  params: ParamsDevice
  devicesList: DevicesInfo[]
  setParams: Dispatch<SetStateAction<ParamsDevice>>
}

export const DeviceFilterContainer: React.FC<PropsI> = ({ params, setParams, devicesList }) => {
  const {t} = useTranslation()

  const handlerSearch = (values: string | null) => {
    setParams({ ...params, search: values })
  }

  const handlerValueOwner = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setParams({ ...params, stringFilters: { ...params.stringFilters, owner: value } })
    } else {
      alertToast('warning', 'Bad address')
    }
  }

  const handlerKeysOwnerValue = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setParams({ ...params, stringFilters: { ...params.stringFilters, keyOwner: value } })
    } else {
      alertToast('warning', 'Bad address')
    }
  }
  const handlerSupplierValue = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setParams({ ...params, stringFilters: { ...params.stringFilters, supplier: value } })
    } else {
      alertToast('warning', 'Bad address')
    }
  }

  const handlerWhitelisted = (whitelisted: string) => {
    const converted = convert(whitelisted)
    setParams({ ...params, booleanFilters: { ...params.booleanFilters, whitelisted: converted! } })
  }
  const handlerActive = (active: string) => {
    const converted = convert(active)
    setParams({ ...params, booleanFilters: { ...params.booleanFilters, active: converted! } })
  }
  const handlerConnected = (connected: string) => {
    const converted = convert(connected)
    setParams({ ...params, booleanFilters: { ...params.booleanFilters, connected: converted! } })
  }
  const handlerVisible = (visible: string) => {
    const converted = convert(visible)
    setParams({ ...params, booleanFilters: { ...params.booleanFilters, visible: converted! } })
  }

  return (
    <Row className={'mb-2 me-2'} align={'middle'}>
      <Col span={4} style={{ marginRight: '10px' }} className={'mr-2'}>
        <InputSearch search={params.search} handlerSearch={handlerSearch} />
      </Col>
      <Col span={3}>
        <DeviceFilterSelector params={params} setParams={setParams} />
      </Col>
      <Content style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={2}>
          <span style={{ alignItems: 'center', fontSize: '18px' }}>{t('Filter by')}:</span>
        </Col>
        <Col span={3}>
          <DropDownFilter
            handlerValueOwner={handlerValueOwner}
            handlerKeysOwnerValue={handlerKeysOwnerValue}
            handlerSupplierValue={handlerSupplierValue}
          />
        </Col>
        <Col span={3}>
          <DeviceStatus
            activeStatus={t('Activated')}
            disabledStatus={t('Deactivated')}
            handleChange={handlerActive}
            placeHolderName={t('Status')}
          />
        </Col>
        <Col span={3}>
          <DeviceStatus
            activeStatus={t('Visible')}
            disabledStatus={t('Disable')}
            handleChange={handlerVisible}
            placeHolderName={t('Visibility')}
          />
        </Col>
        <Col span={3}>
          <DeviceStatus
            activeStatus={t('Whitelisted')}
            disabledStatus={t('None whitelisted')}
            handleChange={handlerWhitelisted}
            placeHolderName={t('Whitelist')}
          />
        </Col>
        <Col span={2}>
          <DeviceStatus
            activeStatus={t('Connected')}
            disabledStatus={t('Disconnected')}
            handleChange={handlerConnected}
            placeHolderName={t('Connect')}
          />
        </Col>
      </Content>
    </Row>
  )
}
