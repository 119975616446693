import { DeviceMain, DevicesInfo } from '../type/DeviceType'
import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row, Table, Tag, Tooltip } from 'antd'
import {
  CheckOutlined,
  CloseOutlined,
  DisconnectOutlined,
  DownOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LinkOutlined,
  MobileOutlined,
  UpOutlined,
  UserOutlined
} from '@ant-design/icons'
import { DevicesList } from './DevicesList'
import styles from '../Device.module.css'
import { DeviceFilterContainer } from '../DeviceHelp/DeviceFilterContainer'
import { ParamsDevice } from '../../../../query/query'
import { GiBarrier, GiElevator, MdDevicesOther } from 'react-icons/all'
import styled from 'styled-components'
import { DeviceListActions } from './DeviceListActions'
import { DeviceStatistics } from '../DeviceContainer'
import SpinnerContainer from '../../../../utils/Spiner/SpinnerContainer'
import { useTranslation } from 'react-i18next'

interface PropsTabI {
  devicesList: DevicesInfo[]
  loading: boolean
  params: ParamsDevice
  setParams: Dispatch<SetStateAction<ParamsDevice>>
  devicesStatistic: DeviceStatistics
}

const IconWrapper = styled.span`
  align-items: center;
  display: flex;
`

export const DeviceListTable: React.FC<PropsTabI> = ({ loading, params, setParams, devicesList, devicesStatistic }) => {
  const { take, total, skip } = params.pagination
  const {t} = useTranslation()
  const currentPage = Math.ceil(skip / take + 1)

  const deviceColumns = [
    {
      title: t('Device name') ,
      dataIndex: 'name',
      align: 'center' as 'center',
      key: 'name',
      ellipsis: true,
      render: (name: string) => (
        <Tooltip placement="top" title={name}>
          <span className={styles.nameStyle}>{name}</span>
        </Tooltip>
      )
    },
    {
      title: t('Description'),
      dataIndex: 'description',
      align: 'center' as 'center',
      key: 'description',
      ellipsis: true,
      render: (description: string) => (
        <Tooltip placement="topRight" title={description}>
          <span className={styles.descriptionHideText}>{description}</span>
        </Tooltip>
      )
    },
    {
      title: t('Device type'),
      dataIndex: 'details',
      width: 200,
      align: 'center' as 'center',
      key: 'details',
      render: (details: DeviceMain) => {
        let color
        let icon
        if (details.deviceType === 'mobile') {
          color = 'blue'
          icon = <MobileOutlined className={styles.columnDeviceIcon} />
        } else if (details.deviceType === 'human') {
          color = 'geekblue'
          icon = <UserOutlined className={styles.columnDeviceIcon} />
        } else if (details.deviceType === 'other') {
          color = 'magenta'
          icon = <MdDevicesOther className={styles.columnDeviceIcon} />
        } else if (details.deviceType === 'car barrier') {
          color = 'purple'
          icon = <GiBarrier className={styles.columnDeviceIcon} />
        } else if (details.deviceType === 'human barrier') {
          color = 'gold'
          icon = <GiBarrier className={styles.columnDeviceIcon} />
        } else if (details.deviceType === 'elevator') {
          color = 'cyan'
          icon = <GiElevator className={styles.columnDeviceIcon} />
        }
        return (
          <Tag className={styles.columnDeviceTag} color={color} key={details.deviceType} icon={icon}>
            {<span> {details.deviceType?.toUpperCase()}</span>}
          </Tag>
        )
      }
    },
    {
      title: t('Status'),
      dataIndex: 'active',
      key: 'active',
      width: 200,
      align: 'center' as 'center',
      render: (active: boolean) => {
        let textStatus = active ? (
          <IconWrapper>
            <CheckOutlined className={styles.deviceStatus} /> ACTIVE
          </IconWrapper>
        ) : (
          <IconWrapper>
            <CloseOutlined className={styles.deviceStatus} /> DISABLE
          </IconWrapper>
        )
        let color = active ? 'green' : 'volcano'
        return (
          <IconWrapper style={{ display: 'flex', justifyContent: 'center' }}>
            <Tag color={color} key={active.toString()}>
              {textStatus}
            </Tag>
          </IconWrapper>
        )
      }
    },
    {
      title: t('Public visible'),
      dataIndex: 'visible',
      ellipsis: true,
      width: 200,
      align: 'center' as 'center',
      key: 'visible',
      render: (visible: boolean) => {
        const textStatus = visible ? (
          <IconWrapper>
            <EyeOutlined className={styles.deviceStatus} /> VISIBLE
          </IconWrapper>
        ) : (
          <IconWrapper>
            <EyeInvisibleOutlined className={styles.deviceStatus} /> DISABLE
          </IconWrapper>
        )
        const color = visible ? 'green' : 'volcano'
        return (
          <IconWrapper style={{ display: 'flex', justifyContent: 'center' }}>
            <Tag color={color} key={visible.toString()}>
              {textStatus}
            </Tag>
          </IconWrapper>
        )
      }
    },
    {
      title: t('Connection'),
      dataIndex: 'connected',
      width: 200,
      align: 'center' as 'center',
      key: 'connected',
      render: (connected: boolean) => {
        let textStatus = connected ? (
          <IconWrapper>
            <LinkOutlined className={styles.deviceStatus} /> CONNECTED
          </IconWrapper>
        ) : (
          <IconWrapper>
            <DisconnectOutlined className={styles.deviceStatus} /> DISCONNECTED
          </IconWrapper>
        )
        let color = connected ? 'green' : 'volcano'
        return (
          <IconWrapper style={{ display: 'flex', justifyContent: 'center' }}>
            <Tag color={color} key={connected.toString()}>
              <span>{textStatus}</span>
            </Tag>
          </IconWrapper>
        )
      }
    },
    {
      title: t('Action'),
      dataIndex: 'address',
      width: 200,
      align: 'center' as 'center',
      key: 'address',
      render: (address: string) => {
        return <DeviceListActions address={address} />
      }
    }
  ]
  return (
    <Row>
      <Col span={24}>
        <Table
          title={(currentPage) => (
            <Row>
              <Col span={19}>
                <DeviceFilterContainer params={params} setParams={setParams} devicesList={devicesList} />
              </Col>
              <Col span={5} style={{ display: 'flex' }}>
                {devicesStatistic ? (
                  <h5 style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    <CheckOutlined style={{ color: 'green', marginRight: '5px' }} /> {t('Active')}:
                    {devicesStatistic.active_true} / {devicesStatistic.all}
                  </h5>
                ) : (
                  <SpinnerContainer />
                )}
                {devicesStatistic ? (
                  <h5 style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                    <EyeOutlined style={{ color: 'green', marginRight: '5px' }} /> {t("Visible")}:
                    {devicesStatistic.visible_true} / {devicesStatistic.all}
                  </h5>
                ) : (
                  <SpinnerContainer />
                )}
                {devicesStatistic ? (
                  <h5 style={{ display: 'flex', alignItems: 'center' }}>
                    <LinkOutlined style={{ color: 'green', marginRight: '5px' }} /> {t('Connect')}:
                    {devicesStatistic.connected_true} / {devicesStatistic.all}
                  </h5>
                ) : (
                  <SpinnerContainer />
                )}
              </Col>
            </Row>
          )}
          pagination={{
            pageSize: take,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            total: total,
            current: currentPage,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (page, pageSize) => {
              setParams({
                ...params,
                pagination: { ...params.pagination, skip: (page - 1) * pageSize!, take: pageSize! }
              })
            }
          }}
          loading={loading}
          bordered={true}
          columns={deviceColumns}
          expandable={{
            indentSize: 25,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ),
            expandRowByClick: true,
            expandedRowRender: (record) => (
              <DevicesList
                key={record.address}
                address={record.address}
                supplier={record.supplier}
                owner={record.owner}
                name={record.name}
                deviceType={record.details.deviceType}
                description={record.description}
                visible={record.visible}
                active={record.active}
                connected={record.connected}
              />
            )
          }}
          dataSource={devicesList}
        />
      </Col>
    </Row>
  )
}
