import React from 'react'
import { Col, Row } from 'reactstrap'
import DashboardInformation from './DashboardInformation'
import DashboardMapsContainer from './DashboardMapsContainer'
import Breadcrumbs from '../../utils/Breadcrumb/Breadcrumb2'
import styles from './Dashboard.module.css'
import { useTranslation } from 'react-i18next'

const Dashboard = (props: any) => {
  const {t} = useTranslation()
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title={t('Dashboards')} breadcrumbItems={t('Dashboard')} />
        <Row className={styles.dashboardWrapper}>
          <Col xl="4" className={styles.dashboardCardInfo}>
            <DashboardInformation />
          </Col>
          <Col xl="8">
            <DashboardMapsContainer />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
