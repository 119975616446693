import { AppStateType } from '../ReduxStore'
import { ThunkAction } from 'redux-thunk'
import { devicesController } from '../../api/Api'
import { DevicesType } from '../typeReducer/devicesType'
import { CreateDevice } from '../../projectComponents/pages/Device/type/DeviceType'
import { devicesActions, ActionsTypes } from './devices.actions'
import { DeviceActionsType } from './devices.actions.type'
import { alertToast } from '../../utils/AlertTips'
import { getBalanceInfo } from '../Auth/AuthReducer'

const InitialState: DevicesType = {
  devicesList: [],
  isLoading: false,
  address: '',
  devicesValue: {
    all: 0,
    active_true: 0,
    visible_true: 0,
    connected_true: 0,
  },
  editDeviceAddress: '',
  mapCenter: {
    lat: 0,
    lng: 0
  },
  statusLoadingDevices: false
}

const devicesReducer = (state = InitialState, action: ActionsTypes) => {
  switch (action.type) {
    case DeviceActionsType.SET_DEVICE_LIST: {
      return {
        ...state,
        devicesList: action.deviceList
      }
    }
    case DeviceActionsType.SET_LOADING_DEVICE: {
      return {
        ...state,
        statusLoadingDevices: action.loadingStatus
      }
    }
    case DeviceActionsType.SET_DEVICES_VALUE: {
      return {
        ...state,
        devicesValue: action.devicesValue
      }
    }
    case DeviceActionsType.DELETE_DEVICE: {
      return {
        ...state,
        devicesList: state.devicesList.filter((devicesList) => devicesList.address !== action.delDeviceAddress)
      }
    }
    case DeviceActionsType.ADD_DEVICE: {
      return {
        ...state,
        devicesList: [...state.devicesList, action.setDevice]
      }
    }

    case DeviceActionsType.SET_GOOGLE_MAPS_ADDRESS: {
      return {
        ...state,
        address: action.setGMAddress
      }
    }
    case DeviceActionsType.SET_LAT: {
      return {
        ...state,
        ...state.mapCenter,
        mapCenter: {
          ...state.mapCenter,
          lat: action.setLat
        }
      }
    }
    case DeviceActionsType.SET_LNG: {
      return {
        ...state,
        ...state.mapCenter,
        mapCenter: {
          ...state.mapCenter,
          lng: action.setLng
        }
      }
    }
    case DeviceActionsType.SET_EDIT_DEVICE_ADDRESS: {
      return {
        ...state,
        editDeviceAddress: action.editDeviceAddress
      }
    }
    default:
      return state
  }
}
type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsTypes>

export const addDevices = (history: any, setDevice: CreateDevice, address: string): ThunkType => {
  return async (dispatch) => {
    const response = await devicesController.addDevice(setDevice)
    if (response.status === 201) {
      dispatch(devicesActions.setLoading(true))
      dispatch(getBalanceInfo(address))
      history.push('/devices')
      alertToast('success', 'Device created')
    }
  }
}

export const editDevices = (
  history: any,
  supplierAddress: string,
  address: string,
  editDevice: CreateDevice
): ThunkType => {
  return async (dispatch) => {
    const response = await devicesController.editDevice(address, editDevice)
    if (response.status === 200) {
      dispatch(devicesActions.setDevice(response.data))
      dispatch(getBalanceInfo(supplierAddress))
      history.push('/devices')
      alertToast('success', 'You change device')
    }
  }
}

export const deleteDevices = (history: any, address: string, supplierAddress: string): ThunkType => {
  return async (dispatch) => {
    const response = await devicesController.removeDevice(address)
    if (response.status === 200) {
      dispatch(devicesActions.deleteDevice(address))
      dispatch(getBalanceInfo(supplierAddress))
      history.push('/devices')
      alertToast('success', 'You are delete device')
    }
  }
}

export default devicesReducer
