import React from 'react'

import { useSelector } from 'react-redux'
import { getLeftMenu } from '../../Selector/selector'
import { useLocation } from 'react-router-dom'
import { SidebarContent } from './SidebarContent'

interface PropsI {
  theme: string
  type: string
  isMobile: boolean
}

const Sidebar: React.FC<PropsI> = ({ type }) => {
  const leftMenu = useSelector(getLeftMenu)
  const location = useLocation()
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="h-100">{leftMenu ? <SidebarContent location={location} /> : null}</div>
      </div>
    </React.Fragment>
  )
}

export default Sidebar
