import React, { Dispatch, SetStateAction } from 'react'
import classes from '../Device.module.css'
import { DevicePhysicalAddress } from '../type/DeviceType'
import { FormItem, Input } from 'formik-antd'
import { Col, Row } from 'antd'
import { Card, CardBody } from 'reactstrap'
import { useTranslation } from 'react-i18next'

interface PropsI {
  devicePhysicalAddress: DevicePhysicalAddress
  setDevicePhysicalAddress: Dispatch<SetStateAction<DevicePhysicalAddress>>
}

export const AddDeviceFormAddress: React.FC<PropsI> = ({ devicePhysicalAddress, setDevicePhysicalAddress }) => {
  const {t} = useTranslation()
  return (
    <>
      <Card style={{ height: '100%' }}>
        <CardBody>
          <h3>{t('Manual address')}</h3>
          <Row>
            <Col span={12}>
              <FormItem
                label={t('Address line 1')}
                className={classes.defaultFormItem}
                name={'addressLine1'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      addressLine1: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.addressLine1}
                  id="addressLine1"
                  name="addressLine1"
                  placeholder={t('Address line 1')}
                />
              </FormItem>
              <FormItem
                label={t('Number')}
                className={classes.defaultFormItem}
                name={'details.physicalAddress.number'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  min={0}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      number: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.number}
                  id="number"
                  name="number"
                  placeholder={t('Number')}
                />
              </FormItem>
              <FormItem
                label={t('City')}
                className={classes.defaultFormItem}
                name={'city'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      city: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.city}
                  id="city"
                  name="city"
                  placeholder={t('City')}
                />
              </FormItem>

              <FormItem
                label={t('State')}
                className={classes.defaultFormItem}
                name={'state'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      state: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.state}
                  id="state"
                  name="state"
                  placeholder={t('State')}
                />
              </FormItem>
            </Col>
            <Col span={12} style={{ paddingLeft: '1rem' }}>
              <FormItem
                label={t('Address line 2')}
                className={classes.defaultFormItem}
                name={'addressLine2'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      addressLine2: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.addressLine2}
                  id="addressLine2"
                  name="addressLine2"
                  placeholder={t('Address line 2')}
                />
              </FormItem>
              <FormItem
                label={t('Floor')}
                className={classes.defaultFormItem}
                name={'floor'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  min={0}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      floor: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.floor}
                  id="floor"
                  name="floor"
                  placeholder={t('Floor')}
                />
              </FormItem>
              <FormItem
                label={t('Postcode')}
                className={classes.defaultFormItem}
                name={'postcode'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      postcode: e.target.value
                    })
                  }
                  value={devicePhysicalAddress.postcode}
                  id="postcode"
                  name="postcode"
                  placeholder={t('Postcode')}
                />
              </FormItem>
              <FormItem
                label={t('Country')}
                className={classes.defaultFormItem}
                name={'country'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  id="country"
                  name="country"
                  value={devicePhysicalAddress.country}
                  onChange={(e) =>
                    setDevicePhysicalAddress({
                      ...devicePhysicalAddress,
                      country: e.target.value
                    })
                  }
                  placeholder={t('Country')}
                />
              </FormItem>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
