import React from 'react'
import { Card, CardBody, CardTitle, Col, Media } from 'reactstrap'
import { MapToDeviceContainer } from '../../../../utils/GoogleMaps/MapToDeviceContainer'
import { DevicesInfo } from '../type/DeviceType'
import styles from '../Device.module.css'
import { useTranslation } from 'react-i18next'

interface PropsI {
  project: DevicesInfo
}

export const LocationDetails: React.FC<PropsI> = ({ project }) => {
  const { details } = project
  const { t } = useTranslation()
  if (details.physicalAddress) {
    return (
      <Card style={{ height: '100%' }}>
        <CardBody>
          <CardTitle className='mb-4'>{t('Location Details')}:</CardTitle>
          <div
            className='table-responsive'
            style={{ position: 'relative', height: '300px', width: '100%', justifyContent: 'space-between' }}
          >
            <Col sm='6' xs='6' style={{ position: 'absolute', height: '100%' }}>
              <MapToDeviceContainer lat={project.lat} lng={project.lng} />
            </Col>
            <Col sm='6' xs='6' style={{ float: 'right' }}>
              <Media className='overflow-hidden text-muted' body>
                {details.physicalAddress.country ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('Country')}: <span className={styles.textInfo}>{details.physicalAddress.country}</span>
                  </p>
                ) : null}
                {details.physicalAddress.state ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('State')}: <span className={styles.textInfo}>{details.physicalAddress.state}</span>
                  </p>
                ) : null}
                {details.physicalAddress.city ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('City')}: <span className={styles.textInfo}>{details.physicalAddress.city}</span>
                  </p>
                ) : null}
                {details.physicalAddress.addressLine1 ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('Address 1')}: <span className={styles.textInfo}>{details.physicalAddress.addressLine1}</span>
                  </p>
                ) : null}
                {details.physicalAddress.addressLine2 ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('Address 2')}: <span className={styles.textInfo}>{details.physicalAddress.addressLine2}</span>
                  </p>
                ) : null}
                {details.physicalAddress.number ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('Number')}: <span className={styles.textInfo}>{details.physicalAddress.number}</span>
                  </p>
                ) : null}
                {details.physicalAddress.floor ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('Floor')}: <span className={styles.textInfo}>{details.physicalAddress.floor}</span>
                  </p>
                ) : null}
                {details.physicalAddress.postcode ? (
                  <p>
                    <i className='mdi mdi-chevron-right text-primary me-1 ' />
                    {t('Postcode')}: <span className={styles.textInfo}>{details.physicalAddress.postcode}</span>
                  </p>
                ) : null}
              </Media>
            </Col>
          </div>
        </CardBody>
      </Card>
    )
  } else {
    return null
  }
}
