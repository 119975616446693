import React, { useState } from 'react'
import { Button, Dropdown, Space } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { DropdownKeyMenu } from './DropdownKeyMenu'
import { useTranslation } from 'react-i18next'

interface PropsI {
  handlerValueIssuer: (value: string) => void
  handlerValueOwner: (value: string) => void
  handlerValueDevice: (value: string) => void
}

export const KeyFilterDropdown: React.FC<PropsI> = ({ handlerValueDevice, handlerValueIssuer, handlerValueOwner }) => {
  const [visible, setVisible] = useState(false)
  const {t} = useTranslation()

  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown
          overlayStyle={{ width: '500px' }}
          overlay={
            <DropdownKeyMenu
              handlerValueDevice={handlerValueDevice}
              handlerValueIssuer={handlerValueIssuer}
              handlerValueOwner={handlerValueOwner}
            />
          }
          placement="bottomCenter"
          arrow={true}
          visible={visible}
          trigger={['click']}
          onVisibleChange={(visible) => setVisible(visible)}
        >
          <Button>
            {t('Address')} <DownOutlined />
          </Button>
        </Dropdown>
      </Space>
    </Space>
  )
}
