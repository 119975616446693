import moment from 'moment'
import * as yup from 'yup'
import { alertToast } from './AlertTips'

export const timeFromNow = (date: any) => {
  return moment(date).fromNow()
}

export const checkTime = (date: number) => {
  return date - Date.now()
}

export const handleClipboardCopy = (address: string) => {
  navigator.clipboard.writeText(address)
  alertToast('success', 'Copied')
}

export const parseValue = (value: any): string => {
  if (typeof value === 'boolean') {
    return value ? 'true' : 'false'
  }

  if (typeof value === 'object') {
    return JSON.stringify(value)
  }

  return value.toString()
}

export const schema = yup.object({
  address: yup
    .string()
    .required('Please Enter correct address')
    .test('regex', 'Address must be min 35 characters', (val: any) => {
      let regExp = new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
      return regExp.test(val)
    })
})

export const validateAddress = (address: string) => {
  const addressRegEx = /^[1-9A-HJ-NP-Za-km-z]{35}$/
  return addressRegEx.test(String(address))
}

export const convert = (params: string) => {
  let x
  if (params === 'null') {
    x = null
  } else if (params === 'true') {
    x = true
  } else if (params === 'false') {
    x = false
  }
  return x
}
