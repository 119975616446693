import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, Col, Modal, Row } from 'reactstrap'
import moment, { Moment } from 'moment'
import { KeyDataI } from '../../Redux/typeReducer/KeysType'
import { useDispatch, useSelector } from 'react-redux'
import { generateKey } from '../../Redux/Keys/KeysReducer'
import { useHistory } from 'react-router-dom'
import { getSupplierAddress } from '../../Selector/selector'
import { DatePicker, Form, Input, Select, Tag } from 'antd'
import { DevicesListMainInfo } from '../pages/Device/type/DeviceType'
import { useQuery } from '@apollo/client'
import { DevicesSelectList } from '../../query/Device'
import { FieldTimeOutlined, InfoCircleOutlined } from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/pl_PL'
import styles from './Keys.module.css'
const { Option } = Select

interface PropsI {
  generateModalCenter: boolean
  togModal: () => void
  setGenerateModalCenter: Dispatch<SetStateAction<boolean>>
  deviceGenerateKey?: string
}

export const GenerateKey: React.FC<PropsI> = ({
  togModal,
  generateModalCenter,
  setGenerateModalCenter,
  deviceGenerateKey
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const supplierAddress = useSelector(getSupplierAddress)

  const validateMessages = {
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      regexp: '${label} is not a valid address'
    }
  }

  function disabledDate(current: Moment) {
    const weekStart = moment().add(1, 'days')
    return !weekStart.isSameOrBefore(current)
  }

  const date = moment().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss')

  const [deviceData, setDeviceData] = useState<KeyDataI>({ device: '', amount: 0, recipient: '', validTo: date })
  const [deviceAddress, setDeviceAddress] = useState<string | null>(null)
  const [fetching, setFetching] = React.useState(false)

  const [dataDevices, setDataDevices] = useState<DevicesListMainInfo[]>([])

  const { refetch } = useQuery(DevicesSelectList, {
    variables: {
      supplier: supplierAddress,
      search: deviceAddress ?? null,
      whitelisted: true,
      skip: 0,
      take: 10
    },
    onCompleted: (data) => {
      setFetching(false)
      setDataDevices(data.devices.objects)
    }
  })

  const onSubmit = () => {
    dispatch(generateKey(deviceData, history, supplierAddress))
    togModal()
  }

  return (
    dataDevices && (
      <Modal
        isOpen={generateModalCenter}
        toggle={() => {
          togModal()
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Generate Key</h5>
          <button
            type="button"
            onClick={() => {
              setGenerateModalCenter(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form validateMessages={validateMessages} onFinish={onSubmit}>
            <Row className="mb-3">
              <Col span={12}>
                <Form.Item
                  className={styles.formWrapper}
                  validateFirst={true}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Device address"
                  tooltip={{ title: 'Device address', icon: <InfoCircleOutlined /> }}
                >
                  <Select
                    inputValue={deviceGenerateKey}
                    onSearch={async (value) => {
                      await refetch({ search: value })
                    }}
                    loading={fetching}
                    onChange={(value) => {
                      setDeviceAddress(value.value)
                      setFetching(true)
                    }}
                    onClear={() => {
                      setDeviceAddress(null)
                    }}
                    showSearch
                    labelInValue
                    style={{ width: '100%' }}
                    optionFilterProp="children"
                    onSelect={(option: any) => {
                      setDeviceData((prevState) => {
                        return { ...prevState, device: option.value }
                      })
                    }}
                    filterOption={(input, option) => {
                      return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {deviceGenerateKey ? (
                      <Option value={deviceGenerateKey} key={deviceGenerateKey}>
                        {deviceGenerateKey}
                      </Option>
                    ) : (
                      dataDevices.map((device) => (
                        <Option value={device.address} key={device.address}>
                          {`${device.address} - ${device.name}`}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>

                <Form.Item
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Amount"
                  tooltip={{ title: 'Amount', icon: <InfoCircleOutlined /> }}
                  style={{ width: '100%' }}
                  rules={[
                    {
                      min: 1,
                      max: 80,
                      message: 'Provide correct amount of keys!'
                    }
                  ]}
                >
                  <Input
                    type={'number'}
                    min={1}
                    max={80}
                    value={deviceData.amount}
                    id="amount"
                    required
                    name="amount"
                    placeholder="amount"
                    onChange={(e) => {
                      setDeviceData((prevState) => {
                        return { ...prevState, amount: Number(e.target.value) }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item
                  validateFirst={true}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Recipient address"
                  tooltip={{ title: 'Recipient address', icon: <InfoCircleOutlined /> }}
                  style={{ width: '100%' }}
                  rules={[
                    {
                      message: 'Please input correct address!',
                      pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
                    }
                  ]}
                >
                  <Input
                    id="recipient"
                    name="recipient"
                    value={deviceData.recipient}
                    placeholder="recipient"
                    onChange={(e) => {
                      setDeviceData((prevState) => {
                        return { ...prevState, recipient: e.target.value }
                      })
                    }}
                  />
                </Form.Item>

                <Form.Item
                  validateFirst={true}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  label="Valid date"
                  tooltip={{ title: 'Valid date', icon: <InfoCircleOutlined /> }}
                  style={{ width: '100%' }}
                  rules={[
                    {
                      type: 'date',
                      message: 'Provide correct date!'
                    }
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    locale={locale}
                    defaultPickerValue={moment(date).add(25, 'hour')}
                    id="validTo"
                    name="validTo"
                    placeholder={'Valid date'}
                    showTime
                    disabledDate={disabledDate}
                    onChange={(value) => {
                      setDeviceData((prevState) => {
                        return { ...prevState, validTo: moment(value).format('YYYY-MM-DD HH:mm:ss') }
                      })
                    }}
                  />
                  <span style={{ alignItems: 'center', display: 'flex', float: 'right', marginTop: '5px' }}>
                    <Tag
                      icon={<FieldTimeOutlined />}
                      style={{ alignItems: 'center', display: 'flex' }}
                      placeholder={'date'}
                      color={'var(--logoColor)'}
                    >
                      {moment(deviceData.validTo).fromNow()}
                    </Tag>
                  </span>
                </Form.Item>
              </Col>
            </Row>
            <Button type="submit">Generate</Button>
          </Form>
        </div>
      </Modal>
    )
  )
}
