import { ThunkAction } from 'redux-thunk'
import { keysController } from '../../api/Api'
import { alertToast } from '../../utils/AlertTips'
import { AppStateType } from '../ReduxStore'
import { KeyDataI, KeysType } from '../typeReducer/KeysType'
import { actions, ActionsTypes } from './keys.actions'
import { KeysActionsType } from './keys.actions.type'
import { getBalanceInfo } from '../Auth/AuthReducer'

const InitialState: KeysType = {
  keysList: [],
  keyListDevice: [],
  keysValue: {
    all: 0,
    burned_true: 0
  },
  assetId: '',
  statusLoadingKeys: true
}

const keysReducer = (state = InitialState, action: ActionsTypes) => {
  switch (action.type) {
    case KeysActionsType.SET_LIST_KEYS: {
      return {
        ...state,
        keysList: action.setListKeys,
        statusLoadingKeys: false
      }
    }
    case KeysActionsType.SET_DEVICE_KEYS_LIST: {
      return {
        ...state,
        keysList: action.setDeviceKeyList
      }
    }
    case KeysActionsType.SET_KEYS_VALUE: {
      return {
        ...state,
        keysValue: action.keysValue
      }
    }
    case KeysActionsType.DELETE_KEY: {
      return {
        ...state,
        keysList: state.keysList.filter((item) => item.assetId !== action.deleteKeyAssetId)
      }
    }
    case KeysActionsType.SET_ASSETID_KEY: {
      return {
        ...state,
        assetId: action.assetId
      }
    }
    case KeysActionsType.REMOVE_KEY_FROM_WHITE_LIST: {
      return {
        ...state,
        keysList: state.keysList.filter((item) => item.assetId !== action.assetId)
      }
    }
    default:
      return state
  }
}

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsTypes>

export const burnedKey = (assetId: string, supplierAddress: string): ThunkType => {
  return async (dispatch) => {
    const response = await keysController.removeKey(assetId)
    if (response.status === 200) {
      dispatch(actions.deleteKey(assetId))
      dispatch(getBalanceInfo(supplierAddress))
      alertToast('success', 'Key was burned')
    }
  }
}

export const generateKey = (KeyData: KeyDataI, history: any, supplierAddress: string): ThunkType => {
  return async (dispatch) => {
    const response = await keysController.generateKey({
      ...KeyData,
      recipient: KeyData.recipient === '' ? undefined : KeyData.recipient
    })
    if (response.status === 201) {
      history.push('/keys')
      alertToast('success', 'Keys generated')
      dispatch(getBalanceInfo(supplierAddress))
    }
  }
}

export const transferKey = (assetId: string, address: string, supplierAddress: string): ThunkType => {
  return async (dispatch) => {
    const response = await keysController.sendKey(assetId, address)
    if (response.status === 200) {
      dispatch(getBalanceInfo(supplierAddress))
      alertToast('success', 'Keys transfer')
    }
  }
}

export const addKeyToDevice = (deviceAddress: string, assetId: string, supplierAddress: string): ThunkType => {
  return async (dispatch) => {
    const response = await keysController.addKeyToDevice(deviceAddress, assetId)
    if (response.status === 201) {
      alertToast('success', 'Keys add to device')
      dispatch(getBalanceInfo(supplierAddress))
    }
  }
}

export const removeKeyFromDevice = (deviceAddress: string, assetId: string, supplierAddress: string): ThunkType => {
  return async (dispatch) => {
    const response = await keysController.removeKeyFromDevice(deviceAddress, assetId)
    if (response.status === 200) {
      dispatch(actions.removeKeyFromWhiteList(assetId))
      dispatch(getBalanceInfo(supplierAddress))
      alertToast('success', 'Keys remove from device')
    }
  }
}

export default keysReducer
