import axios from 'axios'
import { AuthType, SetupData } from '../Redux/typeReducer/authType'
import { KeyDataI } from '../Redux/typeReducer/KeysType'
import moment from 'moment'
import { CreateDevice } from '../projectComponents/pages/Device/type/DeviceType'
import { alertToast } from '../utils/AlertTips'

const supplierApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_SUPPLIER_API
})

const blockchainApiInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_BLOCKCHAIN_API
})

supplierApiInstance.interceptors.response.use(undefined, (error) => {
  if (error.status === 401) {
    localStorage.clear()
    alertToast('warning', 'Some wrong')
    console.log('error confirm ')
    localStorage.clear()
  }
  alertToast('warning', 'Some wrong')
  return Promise.reject(error)
})

supplierApiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('myAccessToken')
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const authAPI = {
  login(payload: {}) {
    const token = localStorage.getItem('myAccessToken')

    return supplierApiInstance.post<AuthType>(
      `auth/login`,
      { ...payload },
      { headers: { Authorization: `Bearer ${token}` } }
    )
  }
}

export const devicesController = {
  addDevice(setDevice: CreateDevice) {
    return supplierApiInstance.post(`/devices`, setDevice)
  },
  removeDevice(address: string) {
    return supplierApiInstance.delete(`/devices/${address}`)
  },
  editDevice(address: string, editDevice: CreateDevice) {
    return supplierApiInstance.put(`/devices/${address}`, editDevice)
  }
}

export const keysController = {
  generateNewKey() {
    return supplierApiInstance.post(`/keys`)
  },
  removeKey(assetId: string) {
    return supplierApiInstance.delete(`/keys/${assetId}`)
  },

  generateKey(keyData: KeyDataI) {
    const payload = {
      ...keyData,
      amount: Number(keyData.amount),
      validTo: Number(moment(keyData.validTo).format('x'))
    }
    return supplierApiInstance.post(`/keys`, payload)
  },

  sendKey(assetId: string, address: string) {
    return supplierApiInstance.put(`/keys/${assetId}/transfer/${address}`)
  },

  addKeyToDevice(address: string, assetId: string) {
    return supplierApiInstance.post(`/devices/${address}/keys/${assetId}`)
  },

  removeKeyFromDevice(address: string, assetId: string) {
    return supplierApiInstance.delete(`/devices/${address}/keys/${assetId}`)
  }
}

export const utilsController = {
  showConfig() {
    return supplierApiInstance.get(`/utils/status`)
  },
  setUpConfig(setConfig: SetupData) {
    const dataScript = {
      ...setConfig
    }
    return supplierApiInstance.post(`/utils/setup`, { ...dataScript })
  },

  showBalance(address: string) {
    return blockchainApiInstance.get(`/addresses/balance/${address}`)
  }
}
