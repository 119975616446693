import React from 'react'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'

interface PropsI {
  search: string | null
  handlerSearch: (value: string | null) => void
}

export const InputSearch: React.FC<PropsI> = ({ search, handlerSearch }) => {
  const {t} = useTranslation()
  return (
    <Input.Search
      style={{ margin: 0 }}
      allowClear
      name={'search'}
      value={search!}
      placeholder={t('Search...')}
      enterButton={true}
      onPressEnter={(event) => {
        handlerSearch(event.currentTarget.value)
      }}
      onChange={(event) => {
        handlerSearch(event.currentTarget.value)
      }}
    />
  )
}
