import React, { Dispatch, SetStateAction } from 'react'
import classes from '../Device.module.css'
import { FormItem, Input } from 'formik-antd'
import { Col, Row, Select, Switch } from 'antd'
import { DeviceMain } from '../type/DeviceType'
import { Card, CardBody } from 'reactstrap'
import TextArea from 'antd/es/input/TextArea'
import { useTranslation } from 'react-i18next'
const { Option } = Select

interface PropsI {
  deviceMain: DeviceMain
  setDeviceMain: Dispatch<SetStateAction<DeviceMain>>
}

const validateRequired = (value: string) => {
  return value ? undefined : 'required'
}

const AddDeviceFormMain: React.FC<PropsI> = ({ deviceMain, setDeviceMain }) => {
  const {t} = useTranslation()
  return (
    <>
      <Card className={classes.deviceCardWithMarginWrapper}>
        <CardBody>
          <h3>{t('Main information')}</h3>
          <Row>
            <Col span={24}>
              <FormItem
                label={t('Name')}
                className={classes.defaultFormItem}
                name={'name'}
                validate={validateRequired}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  id="name"
                  name="name"
                  value={deviceMain.name}
                  onChange={(e: any) =>
                    setDeviceMain({
                      ...deviceMain,
                      name: e.target.value
                    })
                  }
                  placeholder={t('Your name')}
                  required={true}
                />
              </FormItem>
              <FormItem
                label={t('Description')}
                className={classes.defaultFormItem}
                name={'description'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  className={classes.containerForm}
                  value={deviceMain.description}
                  onChange={(e) =>
                    setDeviceMain({
                      ...deviceMain,
                      description: e.target.value
                    })
                  }
                  id="description"
                  name="description"
                  placeholder={t('Example device description')}
                />
              </FormItem>
              <FormItem
                label={t('Device type')}
                className={classes.defaultFormItem}
                name={'deviceType'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  value={deviceMain.deviceType}
                  onChange={(value) => setDeviceMain({ ...deviceMain, deviceType: value })}
                >
                  <Option value={'car barrier'}>car barrier</Option>
                  <Option value={'human barrier'}>human barrier</Option>
                  <Option value={'elevator'}>elevator</Option>
                  <Option value={'mobile'}>mobile</Option>
                  <Option value={'other'}>other</Option>
                </Select>
              </FormItem>
              <div style={{ display: 'flex' }}>
                <FormItem
                  label={t('Active')}
                  className={classes.formItemSelect}
                  style={{ marginRight: '0.5rem' }}
                  name={'active'}
                >
                  <Switch defaultChecked={true} onChange={(e) => setDeviceMain({ ...deviceMain, active: e })} />
                </FormItem>
                <FormItem
                  label={t('Connected')}
                  className={classes.formItemSelect}
                  style={{ marginRight: '0.5rem' }}
                  name={'connected'}
                >
                  <Switch defaultChecked={true} onChange={(e) => setDeviceMain({ ...deviceMain, connected: e })} />
                </FormItem>
                <FormItem label={t('Visible')} className={classes.formItemSelect} name={'visible'}>
                  <Switch defaultChecked={true} onChange={(e) => setDeviceMain({ ...deviceMain, visible: e })} />
                </FormItem>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}

export default AddDeviceFormMain
