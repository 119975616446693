import { gql } from '@apollo/client'

export const GetAllKeys = gql`
  query Keys(
    $issuer: String
    $take: Int
    $skip: Int
    $order: String
    $search: String
    $orderBy: String
    $owner: String
    $device: String
    $burned: Boolean
  ) {
    keys(
      filter: { issuer: $issuer, owner: $owner, device: $device, burned: $burned }
      take: $take
      search: $search
      skip: $skip
      order: $order
      orderBy: $orderBy
    ) {
      objects {
        assetId
        issuer
        owner
        name
        device
        validTo
        issueTimestamp
        burned
        createdAt
        updatedAt
        whitelisted
        deviceName
      }
      meta {
        skip
        take
        total
      }
    }
  }
`

export const GetKeysSelect = gql`
  query Keys($issuer: String, $take: Int, $skip: Int) {
    keys(filter: { issuer: $issuer, burned: false }, take: $take, skip: $skip) {
      objects {
        assetId
        issuer
        owner
        name
        device
        validTo
        issueTimestamp
        burned
        createdAt
        updatedAt
      }
      meta {
        skip
        take
        total
      }
    }
  }
`

export const GetKeysForDevice = gql`
  query Keys($device: String, $take: Int, $skip: Int) {
    keys(filter: { device: $device, burned: false }, take: $take, skip: $skip) {
      objects {
        assetId
        issuer
        owner
        name
        device
        validTo
        issueTimestamp
        burned
        createdAt
        updatedAt
        deviceName
        whitelisted
      }
      meta {
        skip
        take
        total
      }
    }
  }
`

