/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { DevicesInfo } from './type/DeviceType'
import { DeviceListMainContainer } from './DeviceList/DeviceListMainContainer'
import { getDevicesList, getDevicesValue, getSupplierAddress } from '../../../Selector/selector'
import { GetAllDevice } from '../../../query/Device'
import { ParamsDevice } from '../../../query/query'
import { devicesActions } from '../../../Redux/Device/devices.actions'

export interface DeviceStatistics {
  all: number
  active_true: number
  visible_true: number
  connected_true: number
}
export const DeviceContainer: React.FC = () => {
  const dispatch = useDispatch()
  const supplierAddress = useSelector(getSupplierAddress)
  const devicesList = useSelector(getDevicesList)
  const devicesStatistic = useSelector(getDevicesValue)

  const [paramsDevice, setParamsDevice] = useState<ParamsDevice>({
    booleanFilters: {
      active: null,
      visible: null,
      connected: null,
      whitelisted: null
    },
    stringFilters: {
      owner: null,
      supplier: null,
      keyOwner: null
    },
    devicesLoading: false,
    pagination: {
      take: 10,
      total: 10,
      skip: 0
    },
    search: null,
    sort: {
      order: 'desc' || 'asc',
      orderBy: null
    }
  })

  const [getDevices] = useLazyQuery(GetAllDevice, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      active: paramsDevice.booleanFilters.active ?? null,
      visible: paramsDevice.booleanFilters.visible ?? null,
      connected: paramsDevice.booleanFilters.connected ?? null,
      whitelisted: paramsDevice.booleanFilters.whitelisted ?? true,
      order: paramsDevice.sort.order ?? 'desc',
      orderBy: paramsDevice.sort.orderBy ?? 'createdAt',
      search: paramsDevice.search ?? null,
      supplier: paramsDevice.stringFilters.supplier ?? supplierAddress,
      skip: paramsDevice.pagination.skip,
      take: paramsDevice.pagination.take,
      keysOwner: paramsDevice.stringFilters.keyOwner ? paramsDevice.stringFilters.keyOwner : null,
      owner: paramsDevice.stringFilters.owner ? paramsDevice.stringFilters.owner : null
    },
    onCompleted: (data) => {
      const devicesListData = data.devices.objects.map((e: DevicesInfo) => ({
        ...e,
        key: e.address
      }))
      dispatch(devicesActions.setLoading(false))
      dispatch(devicesActions.setDevicesList(devicesListData))
      setParamsDevice({ ...paramsDevice, pagination: data.devices.meta })
    }
  })

  useEffect(() => {
    getDevices()
  }, [])

  return (
    <>
      <DeviceListMainContainer
        devicesStatistic={devicesStatistic!}
        params={paramsDevice}
        setParams={setParamsDevice}
        loading={paramsDevice.devicesLoading}
        devicesList={devicesList}
      />
    </>
  )
}
