import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { Form, FormItem, Input } from 'formik-antd'
import { getSupplierAddress } from '../../../../Selector/selector'

// import images
import profileImg from '../../../../assets/images/profile-img.png'
import logoImg from '../../../../assets/image/og-logo.png'
import { setupConfigInfo } from '../../../../Redux/Auth/AuthReducer'
import { useTranslation } from 'react-i18next'

interface Values {
  name: string
  description: string
}

export const SetupFormScreen: React.FC = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const address = useSelector(getSupplierAddress)
  const history = useHistory()

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2" />
          </Link>
        </div>
        <Container fluid={true}>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t('Setup screen')}</h5>
                        <p>{t('Create your setup!')}</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logoImg} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={{ name: '', description: '' }}
                      onSubmit={(value: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
                        dispatch(setupConfigInfo(value, address, history))
                        setSubmitting(false)
                        resetForm({})
                      }}
                    >
                      <Form>
                        <FormItem labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} label={t('Name')} name={'name'}>
                          <Input id="name" name="name" placeholder={t('Name')} />
                        </FormItem>
                        <FormItem
                          label={t('Description')}
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                          name={t('Description')}
                        >
                          <Input id="description" name="description" placeholder={t('Description')} />
                        </FormItem>
                        <Button type={'submit'}>{t('Save')}</Button>
                      </Form>
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Smartkey</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
