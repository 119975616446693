import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { authActions } from '../Redux/Auth/auth.actions'
import { getConfigInfo } from '../Redux/Auth/AuthReducer'

export const useAuthLoading = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [accessLoading, setAccessLoading] = useState(true)
  const accessToken = localStorage.getItem('myAccessToken')
  const userName = localStorage.getItem('myUsername')

  const accessLoad = async () => {
    if (accessToken && userName) {
      dispatch(authActions.setToken(accessToken))
      dispatch(authActions.setUsername(userName))
      await dispatch(getConfigInfo(history))
    } else {
      localStorage.clear()
      history.push('/login')
    }
    setAccessLoading(false)
  }

  useEffect(() => {
    ;(() => accessLoad())()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return accessLoading
}
