import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'
import {EditDeviceForm} from './EditDeviceForm'
import {DeviceInfo} from '../../../../query/Device'
import SpinnerContainer from '../../../../utils/Spiner/SpinnerContainer'
import {DevicesInfo} from '../type/DeviceType'

export const EditDeviceFormContainer: React.FC = () => {
    const {address} = useParams<{ address: string }>()

    const [deviceInfo, setDeviceInfo] = useState<DevicesInfo>()

    useQuery(DeviceInfo, {
        variables: {address: address},
        onCompleted: (data) => {
            setDeviceInfo(data.device)
        }
    })

    if (!deviceInfo) return <SpinnerContainer/>

    return (
        <>
            <EditDeviceForm address={address} deviceInfo={deviceInfo}/>
        </>
    )
}
