import React from 'react'
import { Card, CardBody, CardTitle } from 'reactstrap'
import { parseValue } from '../../../../utils/supportFunctions'

interface PropsI {
  custom?: ''
}

export const CustomInformationDevice: React.FC<PropsI> = ({ custom }) => {
  if (!custom) return null
  const customField = Object.entries(JSON.parse(custom!))
  const result = customField.filter((item: any) => item === ' ').length === 1

  return (
    <>
      {result && (
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Custom Fields</CardTitle>
            {customField.map(([key, value]) => (
              <React.Fragment key={key}>
                <span>{key}: </span>
                <span>{parseValue(value)}</span>
                <br />
              </React.Fragment>
            ))}
          </CardBody>
        </Card>
      )}
    </>
  )
}
