import React, { useState } from 'react'
import { Col, Row, Tooltip } from 'antd'
import { DeleteOutlined, FireOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons'
import { PopConfirm } from '../../../../utils/supportUtils/PopConfirm'
import styles from './Key.module.css'
import { getSupplierAddress } from '../../../../Selector/selector'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const ActionButton = styled.button`
  background: none;
  border: none 0;
  box-shadow: none;
  display: flex;
  align-item: center;
`

interface PropsI {
  device: string
  assetId: string
  owner: string
  whitelisted: boolean
  showTransferKeyModal: (assetId: string) => void
  handleDeleteKeyFromDevice: () => void
  handleAddKeyToDevice: () => void
  burnedKey: () => void
}

export const KeysListActions: React.FC<PropsI> = ({
  device,
  owner,
  assetId,
  handleAddKeyToDevice,
  handleDeleteKeyFromDevice,
  showTransferKeyModal,
  burnedKey,
  whitelisted
}) => {
  const {t} = useTranslation()
  const supplierAddress = useSelector(getSupplierAddress)
  const [visibleBurnedPop, setVisibleBurnedPop] = useState(false)
  const [visibleTransferPop, setVisibleTransferPop] = useState(false)
  const [visibleAddPop, setVisibleAddPop] = useState(false)
  const [visibleDeletePop, setVisibleDeletePop] = useState(false)

  const setVisibleBurnedConfirm = (e: any) => {
    e.stopPropagation()
    setVisibleBurnedPop(!visibleBurnedPop)
  }

  const setVisibleDeleteConfirm = (e: any) => {
    e.stopPropagation()
    setVisibleDeletePop(!visibleDeletePop)
  }

  const setVisibleTransferConfirm = (e: any) => {
    e.stopPropagation()
    setVisibleTransferPop(!visibleTransferPop)
  }

  const setVisibleAddConfirm = (e: any) => {
    e.stopPropagation()
    setVisibleAddPop(!visibleAddPop)
  }

  return (
    <Row>
      <Col span={24} className={styles.iconsWrapper}>
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <Tooltip placement="bottom" title={t('Transfer key')}>
            <ActionButton disabled={supplierAddress !== owner} onClick={(e) => setVisibleTransferConfirm(e)}>
              <SendOutlined
                style={{
                  color: supplierAddress === owner ? 'var(--logoColor)' : '#828282',
                  cursor: supplierAddress === owner ? 'pointer' : 'not-allowed'
                }}
                className={styles.iconDefaultStyle}
                rotate={-35}
              />
              <PopConfirm
                visible={visibleTransferPop}
                icon={<SendOutlined />}
                title={t('Are you sure transfer this key?')}
                handler={() => {
                  showTransferKeyModal(assetId)
                }}
              />
            </ActionButton>
          </Tooltip>

          <Tooltip placement="bottom" title={t('Burned key')}>
            <ActionButton onClick={(e) => setVisibleBurnedConfirm(e)} disabled={supplierAddress !== owner}>
              <FireOutlined
                style={{
                  color: supplierAddress === owner ? 'red' : 'grey',
                  cursor: supplierAddress === owner ? 'pointer' : 'not-allowed'
                }}
                className={styles.iconDeleteStyle}
              />
              <PopConfirm
                visible={visibleBurnedPop}
                icon={<FireOutlined />}
                title={t('Are you sure to burned this key?')}
                handler={burnedKey}
              />
            </ActionButton>
          </Tooltip>

          {whitelisted ? (
            <Tooltip placement="bottom" title={t('Delete key from white list')}>
              <ActionButton onClick={(e) => setVisibleDeleteConfirm(e)}>
                <DeleteOutlined style={{ color: 'red' }} className={styles.iconDeleteStyle} />
                <PopConfirm
                  visible={visibleDeletePop}
                  icon={<DeleteOutlined />}
                  title={t('Are you sure to delete this key from white list?')}
                  handler={() => handleDeleteKeyFromDevice()}
                />
              </ActionButton>
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title={t('Add key to device')}>
              <span>
                <ActionButton onClick={(e) => setVisibleAddConfirm(e)} disabled={supplierAddress !== owner}>
                  <span>
                    <PlusOutlined style={{ color: 'var(--logoColor)' }} className={styles.iconDefaultStyle} />
                    <PopConfirm
                      visible={visibleAddPop}
                      icon={<PlusOutlined />}
                      title={t('Are you sure add key to device?')}
                      handler={() => handleAddKeyToDevice()}
                    />
                  </span>
                </ActionButton>
              </span>
            </Tooltip>
          )}
        </div>
      </Col>
    </Row>
  )
}
