import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from '../../../../utils/Breadcrumb/Breadcrumb2'
import { DeviceDetail } from './DeviceDetail'
import { LocationDetails } from './LocationDetails'
import { DeviceKeysOverview } from './DeviceKeysOverview'
import { useLazyQuery } from '@apollo/client'
import { DevicesInfo } from '../type/DeviceType'
import { CustomInformationDevice } from './CustomDeviceInformation'
import styles from '../Device.module.css'
import { ArrowLeftOutlined, EditOutlined } from '@ant-design/icons'
import { DeviceInfo } from '../../../../query/Device'
import { useTranslation } from 'react-i18next'

export const DeviceOverview: React.FC = (props) => {
  const { address } = useParams<{ address: string }>()
  const {t} = useTranslation()
  const [deviceInformation, setDeviceInformation] = useState<DevicesInfo>()

  const [deviceInfo] = useLazyQuery(DeviceInfo, {
    variables: { address: address },
    onCompleted: (data) => {
      setDeviceInformation(data.device)
    }
  })

  useEffect(() => {
    deviceInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address])

  if (!deviceInformation) return null

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card style={{ background: '#fdfdfd' }}>
            <CardBody>
              <Row>
                <Col span={12}>
                  <Breadcrumbs title={t('Device')} breadcrumbItems={t('Device Overview')} />
                </Col>

                <Col>
                  <Button
                    style={{ float: 'right' }}
                    type="button"
                    color="success"
                    className="btn-rounded waves-effect waves-light mb-2 me-2"
                  >
                    <Link to={`/edit-device/${address}`}>
                      <span className={styles.textButtonAdd}>
                        <EditOutlined className={styles.iconStyle} />
                        <span>{t('Edit device')}</span>
                      </span>
                    </Link>
                  </Button>

                  <Button
                    style={{ float: 'right' }}
                    type="button"
                    color={'primary'}
                    className="btn-rounded waves-effect waves-light mb-2 me-2"
                  >
                    <Link to={'/devices'}>
                      <span className={styles.textButtonAdd}>
                        <ArrowLeftOutlined className={styles.iconStyle} />
                        <span>{t('Go Back')}</span>
                      </span>
                    </Link>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col lg="8">
                  <DeviceDetail project={deviceInformation} />
                </Col>

                <Col lg="4">
                  <LocationDetails project={deviceInformation} />
                </Col>
              </Row>

              <Row style={{ marginTop: '1rem' }}>
                <Col lg="8">
                  <DeviceKeysOverview address={address} />
                </Col>

                <Col lg="4">
                  <CustomInformationDevice custom={deviceInformation.custom} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
