import React, { useState } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Routes } from './Rout/Routes'
// Import scss
import './assets/scss/theme.scss'
import 'antd/dist/antd.css'
import './App.css'
//import custom hooks
import { useAuthLoading } from './Hooks/useAuthLoading'
import { Layout } from './projectComponents/Layout/Layout'
import UnprotectedShell from './Rout/Shell/UnprotectedShell'
import SpinnerContainer from './utils/Spiner/SpinnerContainer'
import { getScript, getSupplierAddress, getToken } from './Selector/selector'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { actions } from './Redux/Keys/keys.actions'
import { devicesActions } from './Redux/Device/devices.actions'
import { GetStatistics } from './query/Device'

const App: React.FC = () => {
  const accessToken = useSelector(getToken)
  const accessScript = useSelector(getScript)

  const attachShell = (routeData: any) => ({
    ...routeData,
    Shell: routeData.requireScript ? Layout : UnprotectedShell
  })

  const routesFilter = (route: any) => route.requireToken === !!accessToken && route.requireScript === !!accessScript

  const toRouteComponent = (authRoute: any) => {
    const { path, Component, Shell, id } = authRoute
    return (
      <Switch key={id}>
        <Route
          key={id}
          exact={true}
          path={path}
          render={() => (
            <Shell>
              <Component />
            </Shell>
          )}
        />
        {accessToken && accessScript && (
          <Route exact path={'/'}>
            <Redirect to={'dashboard'} />
          </Route>
        )}
        {accessToken && !accessScript && (
          <Route exact path={'/'}>
            <Redirect to={'/utils/setup'} />
          </Route>
        )}
        {!accessToken && !accessScript && (
          <Route exact path={'/'}>
            <Redirect to={'/login'} />
          </Route>
        )}
      </Switch>
    )
  }

  const routes = Routes.map(attachShell).filter(routesFilter).map(toRouteComponent)

  return <>{routes}</>
}

export const PrevApp = React.memo(() => {
  const isLoading = useAuthLoading()
  const accessScript = useSelector(getScript)
  const address = useSelector(getSupplierAddress)
  const [statisticLoading, setStatisticLoading] = useState(true)
  const dispatch = useDispatch()

  useQuery(GetStatistics, {
    variables: {
      id: address
    },
    onCompleted: (data) => {
      setStatisticLoading(false)
      dispatch(devicesActions.setDevicesValue(data.supplierStats.current.data.devices))
      dispatch(actions.setKeysValue(data.supplierStats.current.data.keys))
    },
    onError: (error) => {
      console.log(error)
    }
  })


  return (
    <React.Fragment>
      {isLoading && accessScript === null && statisticLoading && <SpinnerContainer />}
      {!isLoading && <App />}
    </React.Fragment>
  )
})
