import React from 'react'
import styles from '../Device.module.css'
import { handleClipboardCopy } from '../../../../utils/supportFunctions'
import { CopyOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'

interface PropsI {
  address: string
  owner: string
}

export const DeviceListAddress: React.FC<PropsI> = ({ address, owner }) => {
  const {t} = useTranslation()
  return (
    <Col span={6}>
      <div className={styles.textStyle} onClick={() => handleClipboardCopy(address)}>
        {t('Device address')}:
        <span className={styles.textAddress}>{address}</span>
        <CopyOutlined className={styles.iconDefaultStyle} />
      </div>
      <div className={styles.actionCopy} onClick={() => handleClipboardCopy(owner)}>
        {t('Owner address')}:
        <a
          href={`${process.env.REACT_APP_BASE_URL_EXPLORER}${owner}`}
          rel="noreferrer"
          target={'_blank'}
          className={styles.textAddress}
        >
          {owner}
        </a>
        <CopyOutlined className={styles.iconDefaultStyle} />
      </div>
    </Col>
  )
}
