import React, { useState } from 'react'
import { Card, CardBody } from 'reactstrap'
import { getSupplierAddress } from '../../Selector/selector'
import { useSelector } from 'react-redux'
import { useLazyQuery, useQuery } from '@apollo/client'
import { DashBoardDeviceMapInfo } from './DashBoardDeviceMapInfo'
import styles from './Dashboard.module.css'
import { DevicesMap } from '../../utils/GoogleMaps/MapToDeviceListLocation'
import { DeviceZoom } from '../pages/Device/type/DeviceType'
import { DeviceLocation, DevicesZoomLocation } from '../../query/Device'
import { validateAddress } from '../../utils/supportFunctions'
import { alertToast } from '../../utils/AlertTips'
import { useTranslation } from 'react-i18next'

const DashboardMapsContainer = () => {
  const {t} = useTranslation()
  const supplierAddress = useSelector(getSupplierAddress)
  const [devices, setDevices] = useState<DeviceZoom[]>([])
  const [center, setCenter] = useState({ lat: 53.776097260007624, lng: 20.49159059003274 })
  const [load, setLoad] = useState(false)
  const [rectangleLocation, setRectangleLocation] = useState<{
    neLat: number
    neLng: number
    swLat: number
    swLng: number
  }>({ neLat: -90, neLng: -180, swLat: 90, swLng: 180 })
  const { neLat, neLng, swLat, swLng } = rectangleLocation

  const devicesZoom = useQuery(DevicesZoomLocation, {
    variables: {
      supplierAddress: supplierAddress,
      bottomLeft: { lat: swLat, lng: swLng },
      upperRight: { lat: neLat, lng: neLng }
    },
    onCompleted: (data) => {
      setDevices(data.devices.objects)
    }
  })

  const [runQuery] = useLazyQuery(DeviceLocation, {
    onCompleted: (data) => {
      setLoad(false)
      setDevices([data.device])
      setCenter({ lat: data.device.lat, lng: data.device.lng })
    }
  })

  const handleClick = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setLoad(true)
      runQuery({ variables: { load, address: value } })
    } else {
      devicesZoom.refetch()
      alertToast('warning', t('Bad address'))
    }
  }

  return (
    <React.Fragment>
      <Card className={styles.wrapper}>
        <DashBoardDeviceMapInfo handleClick={handleClick} />
        <CardBody className={styles.wrapper}>
          <div className={styles.wrapper}>
            {devices && <DevicesMap center={center} devices={devices} setRectangleLocation={setRectangleLocation} />}
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default DashboardMapsContainer
