import { authAPI, utilsController } from '../../api/Api'
import { AppStateType } from '../ReduxStore'
import { ThunkAction } from 'redux-thunk'
import { AuthType, SetupData } from '../typeReducer/authType'
import { ActionsTypes, authActions } from './auth.actions'
import { AuthActionsType } from './auth.actions.type'
import { alertToast } from '../../utils/AlertTips'

const InitialState: AuthType = {
  username: null,
  loading: true,
  whitelistedSupplier: false,
  config: {
    address: '',
    script: null,
    name: '',
    description: '',
    nodeUrl: '',
    chainId: 'R',
    aliases: []
  },
  balance: null,
  accessToken: null
}
const AuthReducer = (state = InitialState, action: ActionsTypes) => {
  switch (action.type) {
    case AuthActionsType.SET_TOKEN: {
      return {
        ...state,
        accessToken: action.setToken
      }
    }
    case AuthActionsType.SET_USERNAME: {
      return {
        ...state,
        username: action.username
      }
    }
    case AuthActionsType.CREATE_NEW_CONFIG: {
      return {
        ...state,
        accessScript: action.createConfig,
        loading: false
      }
    }
    case AuthActionsType.SET_WHITELISTED_SUPPLIER: {
      return {
        ...state,
        whitelistedSupplier: action.status
      }
    }
    case AuthActionsType.SETUP_CONFIG: {
      return {
        ...state,
        config: action.setupConfig,
        loading: false
      }
    }
    case AuthActionsType.SET_BALANCE: {
      return {
        ...state,
        balance: action.setBalance
      }
    }
    default:
      return state
  }
}

type ThunkType = ThunkAction<Promise<void>, AppStateType, unknown, ActionsTypes>

export const login = (
  history: any,
  payload: {
    email: string
    password: string
  }
): ThunkType => {
  return async (dispatch) => {
    const response = await authAPI.login(payload)
    if (response.status === 201) {
      // @ts-ignore
      dispatch(authActions.setToken(response.data.access_token))
      dispatch(authActions.setUsername(payload.email))
      // @ts-ignore
      localStorage.setItem('myAccessToken', response.data.access_token)
      localStorage.setItem('myUsername', payload.email)
      alertToast('success', 'You are logged')
      history.push('/dashboard')
    }
  }
}

export const getConfigInfo = (history: any): ThunkType => {
  return async (dispatch) => {
    const response = await utilsController.showConfig()
    if (response.data.script === true) {
      dispatch(authActions.setupConfig(response.data))
      dispatch(getBalanceInfo(response.data.address))
    } else if (response.data.script === false) {
      dispatch(authActions.setupConfig(response.data))
      history.push('/utils/setup')
      alertToast('warning', 'Some wrong')
    }
  }
}

export const setupConfigInfo = (setConfig: SetupData, address: string, history: any): ThunkType => {
  return async (dispatch) => {
    await utilsController.setUpConfig(setConfig)
    dispatch(authActions.createNewConfig(true))
    dispatch(getBalanceInfo(address))
    alertToast('success', 'Setup script')
  }
}

export const getBalanceInfo = (address: string): ThunkType => {
  return async (dispatch) => {
    const response = await utilsController.showBalance(address)
    dispatch(authActions.setBalance(response.data.balance))
  }
}
export default AuthReducer
