import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, Col, Modal, Row } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { transferKey } from '../../Redux/Keys/KeysReducer'
import { getAssetId, getSupplierAddress } from '../../Selector/selector'
import { Form, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

interface PropsI {
  isTransferKeyModalVisible: boolean
  setIsTransferKeyModalVisible: Dispatch<SetStateAction<boolean>>
  togModal: () => void
}

export const TransferKey: React.FC<PropsI> = ({
  togModal,
  isTransferKeyModalVisible,
  setIsTransferKeyModalVisible
}) => {
  const dispatch = useDispatch()
  const assetId: string = useSelector(getAssetId)
  const supplierAddress = useSelector(getSupplierAddress)

  const [deviceAddress, setDeviceAddress] = useState<string>('')

  const onSubmit = () => {
    dispatch(transferKey(assetId, deviceAddress!, supplierAddress))
    togModal()
  }
  return (
    <Modal
      isOpen={isTransferKeyModalVisible}
      toggle={() => {
        togModal()
      }}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Transfer Key</h5>
        <button
          type="button"
          onClick={() => {
            setIsTransferKeyModalVisible(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={onSubmit}>
          <Row className="mb-3">
            <Col span={12}>
              <Form.Item
                name={['Device owner', 'address']}
                validateFirst={true}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Device address"
                tooltip={{ title: 'Device address', icon: <InfoCircleOutlined /> }}
                style={{ width: '100%' }}
                rules={[
                  {
                    message: 'Please input correct address!',
                    pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
                  }
                ]}
              >
                <Input.Search
                  value={deviceAddress}
                  className={'ant-input-search-button'}
                  name={'search'}
                  placeholder={'Search device'}
                  enterButton={true}
                  onChange={(e) => {
                    setDeviceAddress(e.target.value)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Button type="submit">Transfer</Button>
        </form>
      </div>
    </Modal>
  )
}
