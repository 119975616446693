import React, { useState } from 'react'
import { Button, Form, Input, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface PropsI {
  handlerValueOwner: (value: string) => void
  handlerKeysOwnerValue: (value: string) => void
  handlerSupplierValue: (value: string) => void
}

export const MenuContainer: React.FC<PropsI> = ({ handlerValueOwner, handlerKeysOwnerValue, handlerSupplierValue }) => {
  const {t} = useTranslation()
  const [listsAddress, setListsAddress] = useState({
    owner: '',
    supplier: '',
    keyOwner: ''
  })
  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      regexp: '${label} is not a valid address'
    }
  }
  return (
    <Space style={{ background: '#fff', padding: '1rem', display: 'block' }}>
      <Form validateMessages={validateMessages}>
        <Form.Item
          name={['Device owner', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t('Device owner')}
          tooltip={{ title: 'Device owner address', icon: <InfoCircleOutlined /> }}
          style={{ width: '100%' }}
          rules={[
            {
              message: 'Please input correct address!',
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <div style={{ display: 'flex' }}>
            <Input
              style={{ width: '100%' }}
              placeholder={t('Device owner')}
              value={listsAddress.owner}
              onChange={(e) => setListsAddress({ ...listsAddress, owner: e.target.value })}
            />
            <Button onClick={() => handlerValueOwner(listsAddress.owner)}>check</Button>
          </div>
        </Form.Item>
        <Form.Item
          name={['Keys owner', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t('Keys owner')}
          tooltip={{ title: 'Keys owner address', icon: <InfoCircleOutlined /> }}
          rules={[
            {
              message: 'Please input correct address!',
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <div style={{ display: 'flex' }}>
            <Input
              value={listsAddress.keyOwner}
              placeholder={t('Keys owner')}
              onChange={(e) => setListsAddress({ ...listsAddress, keyOwner: e.target.value })}
            />
            <Button onClick={() => handlerKeysOwnerValue(listsAddress.keyOwner)}>check</Button>
          </div>
        </Form.Item>
        <Form.Item
          name={['supplier', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t('Supplier address')}
          tooltip={{ title: 'Supplier address', icon: <InfoCircleOutlined /> }}
          style={{ width: '100%' }}
          rules={[
            {
              message: 'Please input correct address!',
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <div style={{ display: 'flex' }}>
            <Input
              value={listsAddress.supplier}
              placeholder={t('Supplier address')}
              onChange={(e) => setListsAddress({ ...listsAddress, supplier: e.target.value })}
            />
            <Button onClick={() => handlerSupplierValue(listsAddress.supplier)}>check</Button>
          </div>
        </Form.Item>
      </Form>
    </Space>
  )
}
