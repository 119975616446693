import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory, withRouter } from 'react-router-dom'

import { authActions } from '../../../Redux/Auth/auth.actions'
import { getUsername, getWhitelistedSupplier } from '../../../Selector/selector'
import { Badge } from 'antd'
import { useTranslation } from 'react-i18next'

const ProfileMenu: React.FC = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const logout = () => {
    history.push('/login')
    dispatch(authActions.setToken(null))
    dispatch(authActions.setupConfig([]))
    localStorage.clear()
  }

  const [menu, setMenu] = useState(false)

  const whitelistedSupplier = useSelector(getWhitelistedSupplier)
  const username = useSelector(getUsername)

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className='d-inline-block'>
        <DropdownToggle className='btn header-item waves-effect' id='page-header-user-dropdown' tag='button'>
          <span className='d-none d-xl-inline-block ms-2 me-1'>
            <Badge status={whitelistedSupplier ? 'success' : 'error'} dot={true}>
              {username}
            </Badge>
          </span>
          <i className='mdi mdi-chevron-down d-none d-xl-inline-block' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <DropdownItem>
            <Link to={'/settings'}>
              <span
                style={{
                  color: '#212529',
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <i className='bx bx-wrench font-size-16 align-middle me-1' />
                {t('Settings')}
              </span>
            </Link>
          </DropdownItem>
          <div className='dropdown-divider' />
          <span
            onClick={logout}>
            <span
              style={{
                color: '#212529',
                cursor: 'pointer',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <i className='bx bx-power-off font-size-16 align-middle me-1 text-danger' />
              {t('Logout')}
            </span>
          </span>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withRouter(ProfileMenu)
