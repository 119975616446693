import React, { Dispatch, SetStateAction } from 'react'
import classes from '../Device.module.css'
import { FormItem, Input } from 'formik-antd'
import { Col, Row } from 'antd'
import MapContainer from '../../../../utils/GoogleMaps/MapContainer'
import { DeviceLocation } from '../type/DeviceType'
import { Card, CardBody } from 'reactstrap'
import { devicesActions } from '../../../../Redux/Device/devices.actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface PropsI {
  deviceLocation: DeviceLocation
  setDeviceLocation: Dispatch<SetStateAction<DeviceLocation>>
}

export const AddDeviceFormLocation: React.FC<PropsI> = ({ deviceLocation, setDeviceLocation }) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  return (
    <>
      <Card className={classes.deviceCardWrapper}>
        <CardBody>
          <h3>{t('Geolocation')}</h3>
          <Col span={24} style={{ marginTop: '2.5rem' }}>
            <Col className={classes.googleMapsContainer}>
              <MapContainer />
            </Col>
            <Row align={'middle'}>
              <Col style={{ marginTop: '1rem', display: 'flex' }} span={24}>
                <Col span={8} style={{ paddingLeft: '1rem' }}>
                  <FormItem label={t('Lat')} className={classes.defaultFormItem} name={'lat'}>
                    <Input
                      type={'number'}
                      className={classes.containerForm}
                      value={deviceLocation.lat}
                      onChange={(e) => {
                        dispatch(devicesActions.setLat(Number(e.target.value)))
                        setDeviceLocation({
                          ...deviceLocation,
                          lat: Number(e.target.value)
                        })
                      }}
                      id="lat"
                      name="lat"
                      placeholder={t('Lat')}
                    />
                  </FormItem>
                </Col>
                <Col span={8} style={{ paddingLeft: '1rem' }}>
                  <FormItem label={t('Lng')} className={classes.defaultFormItem} name={'lng'}>
                    <Input
                      type={'number'}
                      className={classes.containerForm}
                      value={deviceLocation.lng}
                      onChange={(e) => {
                        dispatch(devicesActions.setLng(Number(e.target.value)))
                        setDeviceLocation({
                          ...deviceLocation,
                          lng: Number(e.target.value)
                        })
                      }}
                      id="lng"
                      name="lng"
                      placeholder={t('Lng')}
                    />
                  </FormItem>
                </Col>
                <Col span={8} style={{ paddingLeft: '1rem' }}>
                  <FormItem label={t('Alt')} className={classes.defaultFormItem} name={'alt'}>
                    <Input
                      type={'number'}
                      className={classes.containerForm}
                      onChange={(e) => {
                        setDeviceLocation({
                          ...deviceLocation,
                          alt: Number(e.target.value)
                        })
                      }}
                      value={deviceLocation.alt}
                      id="alt"
                      name="alt"
                      placeholder={t('Alt')}
                    />
                  </FormItem>
                </Col>
              </Col>
            </Row>
          </Col>
        </CardBody>
      </Card>
    </>
  )
}
