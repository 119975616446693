import React from 'react'
import { Col, Row } from 'antd'
import Breadcrumbs from '../../../../utils/Breadcrumb/Breadcrumb2'
import { Button } from 'reactstrap'
import { KeyOutlined } from '@ant-design/icons'

interface PropsIHeader {
  togGenerateKey: () => void
}

export const KeyListMainHeader: React.FC<PropsIHeader> = ({ togGenerateKey }) => {
  return (
    <Row align={'middle'}>
      <Col span={12}>
        <Breadcrumbs title="Keys" breadcrumbItems="Keys List" />
      </Col>
      <Col span={12}>
        <Button
          style={{ float: 'right' }}
          type="button"
          color="success"
          onClick={() => {
            togGenerateKey()
          }}
          className="btn-rounded waves-effect waves-light mb-2 me-2"
        >
          <span style={{ alignItems: 'center', display: 'flex' }}>
            <KeyOutlined style={{ marginRight: '0.2rem', fontSize: '18px' }} /> Generate key
          </span>
        </Button>
      </Col>
    </Row>
  )
}
