import { DeviceMain, DeviceLocation, DetailsList, DevicePhysicalAddress, DeviceCustom } from '../type/DeviceType'

export const parseForm = (
  deviceMain: DeviceMain,
  deviceLocation: DeviceLocation,
  deviceDetails: DetailsList,
  deviceCustom: Array<DeviceCustom>,
  devicePhysicalAddress: DevicePhysicalAddress
) => {
  const customConnect = deviceCustom.reduce((acc, curVal) => `${acc} ${curVal.key} : ${curVal.value},`, '')

  const customData = customConnect
    ?.split(',')
    .map((keyVal) => {
      return keyVal.split(':').map((_) => _.trim())
    })
    .filter((_, index, arr) => index !== arr.length - 1)
    .reduce((accumulator: any, currentValue: any) => {
      accumulator[currentValue[0]] = currentValue[1]
      return accumulator
    }, {})

  const data = Object.assign({
    ...deviceMain,
    ...deviceLocation,
    details: {
      ...deviceDetails,
      deviceType: deviceMain.deviceType,
      physicalAddress: { ...devicePhysicalAddress }
    },
    custom: { ...customData }
  })
  delete data.deviceType
  delete data.additionalDescription
  return data
}
