import React from 'react'
import { Col, Row } from 'antd'
import Breadcrumbs from '../../../../utils/Breadcrumb/Breadcrumb2'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import styles from '../Device.module.css'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

export const DeviceListHeader: React.FC = () => {
  const {t} = useTranslation()
  return (
    <Row align={'middle'}>
      <Col span={12}>
        <Breadcrumbs title={t('Devices')} breadcrumbItems={t('Devices List')} />
      </Col>
      <Col span={12}>
        <Button
          style={{ float: 'right' }}
          type="button"
          color="success"
          className="btn-rounded waves-effect waves-light"
        >
          <Link to={'/add-device'}>
            <span className={styles.textButtonAdd}>
              <PlusOutlined className={styles.iconStyle} /> {t('Add Device')}
            </span>
          </Link>
        </Button>
      </Col>
    </Row>
  )
}
