import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import store from './Redux/ReduxStore'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import './i18n'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { PrevApp } from './App'

const client = new ApolloClient({
  uri: process.env.REACT_APP_BASE_URL_DATA_SERVICE_WORKER,
  cache: new InMemoryCache(),
  headers: {
    'x-api-key': process.env.REACT_APP_API_KEY_DATA_SERVICE_WORKER!
  }
})

const history = createBrowserHistory()

ReactDOM.render(
  <Router history={history}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PrevApp />
      </Provider>
    </ApolloProvider>
  </Router>,
  document.getElementById('root')
)

reportWebVitals()
