import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col, BreadcrumbItem } from 'reactstrap'

const Breadcrumb = (props) => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box align-items-center">
          <h4 className="mb-0 font-size-18">{props.title}</h4>
          <div>
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props.breadcrumbItems}</Link>
              </BreadcrumbItem>
              {/*{breadcrumbItems.map((item, key) => (*/}
              {/*  <BreadcrumbItem key={key} active={key + 1 === itemLength}>*/}
              {/*    <Link to="#">{item.title}</Link>*/}
              {/*  </BreadcrumbItem>*/}
              {/*))}*/}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItems: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
