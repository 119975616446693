/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Container } from 'reactstrap'
import { GetAllKeys } from '../../../query/Keys'
import { KeysListMainContainer } from './KeysList/KeysListMainContainer'
import { useDispatch, useSelector } from 'react-redux'
import { getKeysList, getKeysValue, getSupplierAddress } from '../../../Selector/selector'
import { KeysListType } from '../../../Redux/typeReducer/KeysType'
import { actions } from '../../../Redux/Keys/keys.actions'
import { ParamsKeys } from '../../../query/query'

export interface KeyStatistics {
  all: number
  burned_true: number
}
export const KeysContainer: React.FC = () => {
  const issuerAddress = useSelector(getSupplierAddress)
  const dispatch = useDispatch()
  const deviceKeysInformation = useSelector(getKeysList)
  const keysStatistic = useSelector(getKeysValue)
  console.log(keysStatistic)
  const [paramsKeys, setParamsKeys] = useState<ParamsKeys>({
    booleanFilters: {
      burned: false
    },
    keysLoading: false,
    stringFilters: {
      issuer: null,
      owner: null,
      device: null
    },
    pagination: {
      take: 10,
      total: 10,
      skip: 0
    },
    search: null,
    sort: {
      order: 'desc' || 'asc',
      orderBy: null
    }
  })

  const [getKeys] = useLazyQuery(GetAllKeys, {
    variables: {
      burned: paramsKeys.booleanFilters.burned ?? false,
      issuer: paramsKeys.stringFilters.issuer ?? null,
      owner: paramsKeys.stringFilters.owner ?? issuerAddress,
      device: paramsKeys.stringFilters.device ?? null,
      search: paramsKeys.search ?? null,
      order: paramsKeys.sort.order ?? 'desc',
      orderBy: paramsKeys.sort.orderBy ?? 'createdAt',
      skip: 0,
      take: 10
    },
    onCompleted: (data) => {
      const dataKeys = data.keys.objects.map((e: KeysListType) => ({
        ...e,
        key: e.assetId
      }))
      dispatch(actions.setListKeys(dataKeys))
      setParamsKeys({ ...paramsKeys, pagination: { ...data.keys.meta } })
    }
  })

  useEffect(() => {
    getKeys()
  }, [])


  return (
    <div className="page-content">
      <Container fluid>
        <KeysListMainContainer
          keysStatistic={keysStatistic!}
          setParamsKeys={setParamsKeys}
          paramsKeys={paramsKeys}
          loading={paramsKeys.keysLoading}
          data={deviceKeysInformation}
        />
      </Container>
    </div>
  )
}
