import { CheckOutlined } from '@ant-design/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Col, Media, Row } from 'reactstrap'
import { getSetting } from '../../../../Selector/selector'
import { useTranslation } from 'react-i18next'

interface PropsI {
  showChangeConfigModal: () => void
}

export const ConfigList: React.FC<PropsI> = ({ showChangeConfigModal }) => {
  const config = useSelector(getSetting)
  const {t} = useTranslation()
  const { name, description, address, script, chainId } = config
  return (
    <div className="pt-4">
      <Row>
        <Col xs="12">
          <Media className="overflow-hidded" body>
            <p>
              <i className="mdi mdi-chevron-right text-primary me-1 " />
              {t('Name')}: <span style={{ fontWeight: 'bold' }}>{name}</span>
            </p>

            <p>
              <i className="mdi mdi-chevron-right text-primary me-1 " />
              {t('Description')}: <span style={{ fontWeight: 'bold' }}>{description}</span>
            </p>

            <p>
              <i className="mdi mdi-chevron-right text-primary me-1 " />
              {t('Address')}: <span style={{ fontWeight: 'bold' }}>{address}</span>
            </p>

            <p style={{ display: 'flex' }}>
              <i className="mdi mdi-chevron-right text-primary me-1 " />
              {t('Script')}:
              {script ? (
                <CheckOutlined
                  style={{
                    marginLeft: '0.2rem',
                    color: 'green',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                />
              ) : (
                <CheckOutlined
                  style={{
                    marginLeft: '0.2rem',
                    color: 'red',
                    alignItems: 'center',
                    display: 'flex'
                  }}
                />
              )}
            </p>

            <p>
              <i className="mdi mdi-chevron-right text-primary me-1 " />
              {t('ChainId')}: <span style={{ fontWeight: 'bold' }}>{chainId}</span>
            </p>
          </Media>
          <Button onClick={showChangeConfigModal}>{t('Change')}</Button>
        </Col>
      </Row>
    </div>
  )
}
