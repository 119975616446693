import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsMobile,
  getLeftMenu,
  getLeftSideBarTheme,
  getLeftSideBarType,
  getSupplierAddress
} from '../../Selector/selector'
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { useLazyQuery } from '@apollo/client'
import { CheckStatus } from '../../query/Supplier'
import { authActions } from '../../Redux/Auth/auth.actions'

export const Layout: React.FC = (props: any) => {
  const leftMenu = useSelector(getLeftMenu)
  const leftSideBarType = useSelector(getLeftSideBarType)
  const leftSideBarTheme = useSelector(getLeftSideBarTheme)
  const isMobile = useSelector(getIsMobile)

  const supplierAddress = useSelector(getSupplierAddress)
  const dispatch = useDispatch()

  const [getSupplierStatus] = useLazyQuery(CheckStatus, {
    variables: { address: supplierAddress },
    onCompleted: (data) => {
      dispatch(authActions.setWhitelistedSupplier(data.supplier.whitelisted))
    }
  })

  useEffect(() => {
    getSupplierStatus()
  }, [getSupplierStatus])

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header leftMenu={leftMenu} leftSideBarType={leftSideBarType} />
        <Sidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  )
}
