import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap'
import profile from '../../../assets/images/profile-img.png'
import logo from '../../../assets/image/og-logo.png'
import { Input } from 'antd'
import { Form, Formik } from 'formik'
import { getConfigInfo, login } from '../../../Redux/Auth/AuthReducer'
import { useDispatch } from 'react-redux'
import styles from './Auth.module.css'
import { useTranslation } from 'react-i18next'

export const Login: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const [isLoadingLogin, setIsLoadingLogin] = useState(false)
  const [loginData, setLoginData] = useState<{ email: string; password: string }>({
    email: '',
    password: ''
  })

  const onSubmit = async () => {
    setIsLoadingLogin(true)
    await dispatch(login(history, loginData))
    await dispatch(getConfigInfo(history))
    setIsLoadingLogin(false)
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className={styles.authContainer}>
        <Container className={styles.authWrapper}>
          <Row className={styles.authFormWrapper}>
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">{t('Welcome Back !')}</h5>
                        <p>{t('Sign in to continue to SKey-manager.')}</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img src={logo} alt="" className="rounded-circle" height="34" />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={loginData}
                      onSubmit={(values, { setSubmitting }) => {
                        onSubmit()
                        setSubmitting(false)
                      }}
                    >
                      <Form>
                        <div className="mb-3">
                          {t('Email')}:
                          <Input
                            type={'email'}
                            name={'email'}
                            onChange={(e) => {
                              setLoginData({ ...loginData, email: e.target.value })
                            }}
                            value={loginData.email}
                          />
                        </div>
                        <div className="mb-3">
                          {t('Password')}:
                          <Input
                            type={'password'}
                            name={'Password'}
                            onChange={(e) => {
                              setLoginData({ ...loginData, password: e.target.value })
                            }}
                            value={loginData.password}
                          />
                        </div>
                        <div className="mt-3 d-grid">
                          <Button disabled={isLoadingLogin} type="submit">
                            {t('Log In')}
                          </Button>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} Smartkey</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
