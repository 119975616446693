import { Popconfirm } from 'antd'
import React from 'react'

interface PropsI {
  icon: any
  visible: boolean
  handler: () => void
  title: string
}

export const PopConfirm: React.FC<PropsI> = ({ icon, handler, title, visible }) => {
  const confirm = (e: any) => {
    handler()
  }

  return <Popconfirm icon={icon} visible={visible} title={title} onConfirm={confirm} okText="Yes" cancelText="No" />
}
