import React, { Dispatch, SetStateAction } from 'react'

import { Button, Card, CardBody, Col, Container, Modal, Row } from 'reactstrap'

// import images
import { useDispatch, useSelector } from 'react-redux'
import { Formik, FormikHelpers } from 'formik'
import { Form, FormItem, Input } from 'formik-antd'
import { setupConfigInfo } from '../../../../Redux/Auth/AuthReducer'
import { SetupData } from '../../../../Redux/typeReducer/authType'
import { getSupplierAddress } from '../../../../Selector/selector'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface Values {
  name: string
  description: string
}

interface PropsI {
  togModal: () => void
  configModal: boolean
  setConfigModal: Dispatch<SetStateAction<boolean>>
}

export const ChangeFormScreen: React.FC<PropsI> = ({ togModal, configModal, setConfigModal }) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const address = useSelector(getSupplierAddress)
  const history = useHistory()
  const validateConfirmScript = (value: string) => {
    return value ? undefined : 'required'
  }

  const setupScript = (setConfig: SetupData) => {
    dispatch(setupConfigInfo(setConfig, address, history))
    togModal()
  }
  return (
    <div className="page-content">
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Modal
                  isOpen={configModal}
                  toggle={() => {
                    togModal()
                  }}
                  centered={true}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0">{t('Change Config')}</h5>
                    <button
                      type="button"
                      onClick={() => {
                        setConfigModal(false)
                      }}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Formik
                      initialValues={{ name: '', description: '' }}
                      onSubmit={(value: Values, { setSubmitting, resetForm }: FormikHelpers<Values>) => {
                        setupScript(value)
                        setSubmitting(false)
                        resetForm({})
                      }}
                    >
                      <Form>
                        <FormItem name={'name'} validate={validateConfirmScript}>
                          <Input id="name" name="name" placeholder={t('Name')} />
                        </FormItem>
                        <FormItem name={'description'} validate={validateConfirmScript}>
                          <Input id="description" name="description" placeholder={t('Description')} />
                        </FormItem>
                        <Button type={'submit'}>{t('Save')}</Button>
                      </Form>
                    </Formik>
                  </div>
                </Modal>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
