import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row, Table } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { KeyListMore } from './KeyListMore'
import { KeysListType } from '../../../../Redux/typeReducer/KeysType'
import { ParamsKeys } from '../../../../query/query'
import { KeyListTitle } from './KeyListTitle'
import { KeyStatistics } from '../KeysContainer'

interface PropsI {
  keysStatistic: KeyStatistics
  loading: boolean
  ColumnsKeysList: any
  paramsKeys: ParamsKeys
  supplierAddress: string
  setParamsKeys: Dispatch<SetStateAction<ParamsKeys>>
  data: KeysListType[]
}
export const KeyListMainTable: React.FC<PropsI> = ({
  loading,
  ColumnsKeysList,
  paramsKeys,
  setParamsKeys,
  data,
  keysStatistic
}) => {
  const { take, total, skip } = paramsKeys.pagination
  const currentPage = Math.ceil(skip / take + 1)
  return (
    <Row justify={'center'} align={'middle'}>
      <Col span={24}>
        <Table
          title={(currentPage) => (
            <Row>
            <KeyListTitle keysStatistic={keysStatistic} paramsKeys={paramsKeys} setParamsKeys={setParamsKeys} />
            </Row>
          )}
          pagination={{
            pageSize: take,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true,
            showQuickJumper: true,
            total: total,
            current: currentPage,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '50', '100'],
            onChange: (page, pageSize) => {
              setParamsKeys({
                ...paramsKeys,
                pagination: { ...paramsKeys.pagination, skip: (page - 1) * pageSize!, take: pageSize! }
              })
            }
          }}
          loading={loading}
          bordered={true}
          columns={ColumnsKeysList}
          expandable={{
            indentSize: 25,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <UpOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <DownOutlined onClick={(e) => onExpand(record, e)} />
              ),
            expandRowByClick: true,
            expandedRowRender: (record) => <KeyListMore key={record.id} device={record.device} />
          }}
          dataSource={data}
        />
      </Col>
    </Row>
  )
}
