import React, { Dispatch, SetStateAction } from 'react'
import { Card, CardBody, Container } from 'reactstrap'
import { DevicesInfo } from '../type/DeviceType'
import { ParamsDevice } from '../../../../query/query'
import { DeviceListTable } from './DeviceListTable'
import { DeviceListHeader } from './DeviceListHeader'
import { DeviceStatistics } from '../DeviceContainer'

interface PropsI {
  devicesStatistic: DeviceStatistics
  devicesList: DevicesInfo[]
  loading: boolean
  params: ParamsDevice
  setParams: Dispatch<SetStateAction<ParamsDevice>>
}

export const DeviceListMainContainer: React.FC<PropsI> = ({
  devicesList,
  loading,
  params,
  setParams,
  devicesStatistic
}) => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <DeviceListHeader />
              <DeviceListTable
                devicesStatistic={devicesStatistic}
                setParams={setParams}
                params={params}
                devicesList={devicesList}
                loading={loading}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
