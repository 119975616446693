import { InferActionsTypes } from '../ReduxStore'
import { DeviceActionsType } from './devices.actions.type'
import { CreateDevice } from '../../projectComponents/pages/Device/type/DeviceType'
import { DeviceStatistics } from '../../projectComponents/pages/Device/DeviceContainer'

export type ActionsTypes = InferActionsTypes<typeof devicesActions>

export const devicesActions = {
  setDevice: (setDevice: CreateDevice) => ({ type: DeviceActionsType.ADD_DEVICE, setDevice } as const),
  setDevicesValue: (devicesValue: DeviceStatistics) => ({ type: DeviceActionsType.SET_DEVICES_VALUE, devicesValue } as const),

  setLoading: (loadingStatus: boolean) => ({ type: DeviceActionsType.SET_LOADING_DEVICE, loadingStatus } as const),
  setDevicesList: (deviceList: []) => ({ type: DeviceActionsType.SET_DEVICE_LIST, deviceList } as const),
  setEditDeviceAddress: (editDeviceAddress: string) =>
    ({ type: DeviceActionsType.SET_EDIT_DEVICE_ADDRESS, editDeviceAddress } as const),

  deleteDevice: (delDeviceAddress: string) => ({ type: DeviceActionsType.DELETE_DEVICE, delDeviceAddress } as const),
  setGoogleMapsAddress: (setGMAddress: string) =>
    ({
      type: DeviceActionsType.SET_GOOGLE_MAPS_ADDRESS,
      setGMAddress
    } as const),

  setLat: (setLat: number) => ({ type: DeviceActionsType.SET_LAT, setLat } as const),
  setLng: (setLng: number) => ({ type: DeviceActionsType.SET_LNG, setLng } as const)
}
