import { Col, Row } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import { getBalance } from '../../Selector/selector'
import styles from '../../projectComponents/pages/Device/Device.module.css'
import classes from './Layout.module.css'
import { useTranslation } from 'react-i18next'

const Footer: React.FC = () => {
  const {t} = useTranslation()
  const balance = useSelector(getBalance)
  const convertBalance = (balance! / 10 ** 8).toFixed(3)
  return (
    <React.Fragment>
      <footer className="footer">
        <Container className={classes.containerFooterWrapper} fluid={true}>
          <Row align={'middle'}>
            <Col span={12}>
              {new Date().getFullYear()} © Smartkey {process.env.REACT_APP_CURRENT_VERSION_PROJECT}
            </Col>
            <Col span={12} className={classes.balanceFooterWrapper}>
              <h5 className={styles.textInfo}> {t('Balance')}: {convertBalance} Skey</h5>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
