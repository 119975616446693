import { InferActionsTypes } from '../ReduxStore'
import { KeysListType } from '../typeReducer/KeysType'
import { KeysActionsType } from './keys.actions.type'
import { KeyStatistics } from '../../projectComponents/pages/Keys/KeysContainer'

export type ActionsTypes = InferActionsTypes<typeof actions>

export const actions = {
  setListKeys: (setListKeys: KeysListType[]) => ({ type: KeysActionsType.SET_LIST_KEYS, setListKeys } as const),
  setKeysValue: (keysValue: KeyStatistics) => ({ type: KeysActionsType.SET_KEYS_VALUE, keysValue } as const),
  setDeviceKeysList: (setDeviceKeyList: KeysListType) =>
    ({ type: KeysActionsType.SET_DEVICE_KEYS_LIST, setDeviceKeyList } as const),
  generateKey: (generateNewKey: KeysListType) => ({ type: KeysActionsType.GENERATE_NEW_KEY, generateNewKey } as const),
  deleteKey: (deleteKeyAssetId: string) => ({ type: KeysActionsType.DELETE_KEY, deleteKeyAssetId } as const),
  setAssetIdKey: (assetId: string) => ({ type: KeysActionsType.SET_ASSETID_KEY, assetId } as const),
  removeKeyFromWhiteList: (assetId: string) => ({ type: KeysActionsType.REMOVE_KEY_FROM_WHITE_LIST, assetId } as const)
}
