import React, { Dispatch, SetStateAction } from 'react'
import { Col, Row } from 'antd'
import { ParamsKeys } from '../../../../query/query'
import { convert, validateAddress } from '../../../../utils/supportFunctions'
import { alertToast } from '../../../../utils/AlertTips'
import { DeviceStatus } from '../../Device/DeviceHelp/DeviceStatus'
import { InputSearch } from '../../Device/DeviceHelp/InputSearch'
import { DeviceFilterSelector } from '../../Device/DeviceHelp/DeviceFilterSelector'
import { KeyFilterDropdown } from './KeyFilterDropdown'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'

interface PropsI {
  params: ParamsKeys
  setParams: Dispatch<SetStateAction<ParamsKeys>>
}

export const KeyFilterContainer: React.FC<PropsI> = ({ params, setParams }) => {
  const {t} = useTranslation()

  const handlerSearch = (values: string | null) => {
    setParams({ ...params, search: values })
  }

  const handlerValueDevice = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setParams({ ...params, stringFilters: { ...params.stringFilters, device: value } })
    } else {
      alertToast('warning', t('Bad address') )
    }
  }
  const handlerValueIssuer = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setParams({ ...params, stringFilters: { ...params.stringFilters, issuer: value } })
    } else {
      alertToast('warning', t('Bad address'))
    }
  }
  const handlerValueOwner = (value: string) => {
    const valid = validateAddress(value)
    if (value && valid) {
      setParams({ ...params, stringFilters: { ...params.stringFilters, device: value } })
    } else {
      alertToast('warning', t('Bad address'))
    }
  }

  const handlerBurned = (visible: string) => {
    const converted = convert(visible)
    setParams({ ...params, booleanFilters: { ...params.booleanFilters, burned: converted! } })
  }

  return (
    <Row align={'middle'}>
      <Col span={4} style={{ marginRight: '10px' }} className={'mr-2'}>
        <InputSearch search={params.search} handlerSearch={handlerSearch} />
      </Col>
      <Col span={5}>
        <DeviceFilterSelector params={params} setParams={setParams} />
      </Col>
      <Content style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={3}>
          <span style={{ alignItems: 'center', fontSize: '16px' }}>{t('Filter by')}:</span>
        </Col>
        <Col span={3}>
          <KeyFilterDropdown
            handlerValueDevice={handlerValueDevice}
            handlerValueIssuer={handlerValueIssuer}
            handlerValueOwner={handlerValueOwner}
          />
        </Col>
        <Col span={3}>
          <DeviceStatus
            activeStatus={'Burned'}
            disabledStatus={'Not burned'}
            handleChange={handlerBurned}
            placeHolderName={'Status'}
          />
        </Col>
      </Content>
    </Row>
  )
}
