import { gql } from '@apollo/client'

export const GetAllDevice = gql`
  query (
    $supplier: String
    $take: Int
    $skip: Int
    $search: String
    $orderBy: String
    $order: String
    $active: Boolean
    $connected: Boolean
    $whitelisted: Boolean
    $visible: Boolean
    $owner: String
    $keysOwner: String
  ) {
    devices(
      filter: {
        supplier: $supplier
        whitelisted: $whitelisted
        visible: $visible
        connected: $connected
        active: $active
        owner: $owner
      }
      take: $take
      skip: $skip
      orderBy: $orderBy
      order: $order
      search: $search
      keysOwner: $keysOwner
    ) {
      objects {
        address
        supplier
        owner
        name
        description
        visible
        active
        connected
        details {
          deviceType
        }
      }
      meta {
        skip
        take
        total
      }
    }
  }
`

export const DeviceInfo = gql`
  query Device($address: String!) {
    device(address: $address) {
      address
      name
      description
      lat
      lng
      alt
      details {
        deviceModel
        additionalDescription
        assetUrl
        url
        contactInfo
        physicalAddress {
          addressLine1
          addressLine2
          city
          postcode
          state
          country
          number
          floor
        }
      }
      custom
      createdAt
      updatedAt
    }
  }
`

export const DevicesZoomLocation = gql`
  query DeviceZoomLocation($supplier: String, $bottomLeft: Point!, $upperRight: Point!) {
    devices(filter: { supplier: $supplier }, geoSearch: { bottomLeft: $bottomLeft, upperRight: $upperRight }) {
      objects {
        address
        lat
        lng
      }
      meta {
        skip
        take
        total
      }
    }
  }
`

export const DeviceLocation = gql`
  query Device($address: String!) {
    device(address: $address) {
      address
      lat
      lng
      score
    }
  }
`

export const SearchDevice = gql`
  query SearchDevice($supplier: String, $search: String!, $skip: Int, $take: Int) {
    devices(search: $search, filter: { supplier: $supplier }, skip: $skip, take: $take) {
      objects {
        address
        name
      }
      meta {
        take
        skip
        total
      }
    }
  }
`

export const DevicesSelectList = gql`
  query DevicesSelectList($supplier: String, $search: String, $whitelisted: Boolean) {
    devices(filter: { supplier: $supplier, whitelisted: $whitelisted }, search: $search) {
      objects {
        address
        name
      }
    }
  }
`

export const GetStatistics = gql`
  query devicesStat($id: String!) {
    supplierStats(id: $id) {
      current {
        data {
        keys {
            all
            burned_true
          }
          devices {
            all
            active_true
            visible_true
            connected_true
          }
        }
      }
    }
  }
`

export const GetDeviceStatistics = gql`
  query devicesStat($id: String!) {
    deviceStats(id: $id) {
      current {
        data {
          keys {
            all
            burned_true
          }
        }
      }
    }
  }
`
