import { InferActionsTypes } from '../ReduxStore'
import { SetupConfig } from '../typeReducer/authType'
import { AuthActionsType } from './auth.actions.type'

export type ActionsTypes = InferActionsTypes<typeof authActions>

export const authActions = {
  setAuthUserData: (email: string, password: string) =>
    ({
      type: AuthActionsType.SET_AUTH_USER_DATA,
      payload: { email, password }
    } as const),

  setToken: (setToken: string | null) => ({ type: AuthActionsType.SET_TOKEN, setToken } as const),
  setUsername: (username: string) => ({ type: AuthActionsType.SET_USERNAME, username } as const),

  setWhitelistedSupplier: (status: boolean) => ({ type: AuthActionsType.SET_WHITELISTED_SUPPLIER, status } as const),

  setupConfig: (setupConfig: SetupConfig[]) => ({ type: AuthActionsType.SETUP_CONFIG, setupConfig } as const),
  createNewConfig: (createConfig: boolean) => ({ type: AuthActionsType.CREATE_NEW_CONFIG, createConfig } as const),

  setBalance: (setBalance: number) => ({ type: AuthActionsType.SET_BALANCE, setBalance } as const)
}
