import React, { Dispatch, SetStateAction } from 'react'
import classes from '../Device.module.css'
import { FormItem, Input } from 'formik-antd'
import { Col, Row } from 'antd'
import { DetailsList } from '../type/DeviceType'
import { Card, CardBody } from 'reactstrap'
import TextArea from 'antd/es/input/TextArea'
import { useTranslation } from 'react-i18next'

interface PropsI {
  deviceDetails: DetailsList
  setDeviceDetails: Dispatch<SetStateAction<DetailsList>>
}

export const AddDeviceFormDetails: React.FC<PropsI> = ({ deviceDetails, setDeviceDetails }) => {
  const {t} = useTranslation()
  return (
    <>
      <Card className={classes.deviceCardWithMarginWrapper}>
        <CardBody>
          <h3>{t('Details')}</h3>
          <Row>
            <Col span={12}>
              <FormItem
                label={t('Device model')}
                className={classes.defaultFormItem}
                name={'deviceModel'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDeviceDetails({
                      ...deviceDetails,
                      deviceModel: e.target.value
                    })
                  }
                  value={deviceDetails.deviceModel}
                  id="deviceModel"
                  name="deviceModel"
                  placeholder={t('Device model')}
                />
              </FormItem>
              <FormItem
                label={t('Asset url')}
                className={classes.defaultFormItem}
                name={'details.assetUrl'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDeviceDetails({
                      ...deviceDetails,
                      assetUrl: e.target.value
                    })
                  }
                  value={deviceDetails.assetUrl}
                  id="assetUrl"
                  name="assetUrl"
                  placeholder={t('Asset url')}
                />
              </FormItem>
            </Col>
            <Col span={12} style={{ paddingLeft: '1rem' }}>
              <FormItem
                label={t('Url')}
                className={classes.defaultFormItem}
                name={'url'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDeviceDetails({
                      ...deviceDetails,
                      url: e.target.value
                    })
                  }
                  value={deviceDetails.url}
                  id="url"
                  name="url"
                  placeholder={t('Url')}
                />
              </FormItem>
              <FormItem
                label={t('Contact Info')}
                className={classes.defaultFormItem}
                name={'contactInfo'}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  className={classes.containerForm}
                  onChange={(e) =>
                    setDeviceDetails({
                      ...deviceDetails,
                      contactInfo: e.target.value
                    })
                  }
                  value={deviceDetails.contactInfo}
                  id="contactInfo"
                  name="contactInfo"
                  placeholder={t('Contact info')}
                />
              </FormItem>
            </Col>
            <Row style={{ width: '100%' }}>
              <Col span={24}>
                <FormItem
                  label={t('Additional description')}
                  className={classes.defaultFormItem}
                  name={'additionalDescription'}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <TextArea
                    className={classes.containerForm}
                    value={deviceDetails.additionalDescription}
                    onChange={(e) =>
                      setDeviceDetails({
                        ...deviceDetails,
                        additionalDescription: e.target.value
                      })
                    }
                    id="additionalDescription"
                    name="details.additionalDescription"
                    placeholder={t('Additional description')}
                  />
                </FormItem>
              </Col>
            </Row>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
