import React, { useState } from 'react'

import Breadcrumbs from '../../../../utils/Breadcrumb/Breadcrumb2'

import {
  CreateDevice,
  DetailsList,
  DeviceCustom,
  DeviceLocation,
  DeviceMain,
  DevicePhysicalAddress,
  DevicesInfo
} from '../type/DeviceType'

import { AddDeviceFormAddress } from '../DeviceFormElement/AddDeviceFormAddress'
import { Col, Row } from 'antd'
import { Card, Button, CardBody, Container } from 'reactstrap'
import { Form, Formik, FormikHelpers } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { editDevices } from '../../../../Redux/Device/DevicesReducer'
import { parseForm } from '../DeviceSupportFunc/supportDeviceForm'
import { AddDeviceFormCustom } from '../DeviceFormElement/AddDeviceFormCustom'
import { AddDeviceFormDetails } from '../DeviceFormElement/AddDeviceFormDetails'
import { AddDeviceFormLocation } from '../DeviceFormElement/AddDeviceFormLocation'
import AddDeviceFormMain from '../DeviceFormElement/AddDeviceFormMain'
import { getSupplierAddress } from '../../../../Selector/selector'
import { Link, useHistory } from 'react-router-dom'
import styles from '../Device.module.css'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface PropsI {
  deviceInfo: DevicesInfo
  address: string
}

export const EditDeviceForm: React.FC<PropsI> = ({ deviceInfo, address }) => {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const history = useHistory()
  const supplierAddress = useSelector(getSupplierAddress)

  const [isLoading, setIsLoading] = useState(false)

  const [deviceMain, setDeviceMain] = useState<DeviceMain>({
    name: deviceInfo.name || '',
    description: deviceInfo.description || '',
    deviceType:
      deviceInfo.details.deviceType || 'car barrier' || 'human barrier' || 'elevator' || 'human' || 'mobile' || 'other',

    active: deviceInfo.active || true,
    connected: deviceInfo.connected || true,
    visible: deviceInfo.visible || true
  })

  const [deviceDetails, setDeviceDetails] = useState<DetailsList>({
    deviceModel: deviceInfo.details.deviceModel || '',
    assetUrl: deviceInfo.details.assetUrl || '',
    additionalDescription: deviceInfo.details.additionalDescription || '',
    url: deviceInfo.details.url || '',
    contactInfo: deviceInfo.details.contactInfo || ''
  })

  const [deviceLocation, setDeviceLocation] = useState<DeviceLocation>({
    lat: deviceInfo.lat || 0,
    lng: deviceInfo.lng || 0,
    alt: deviceInfo.alt || 0
  })

  const [devicePhysicalAddress, setDevicePhysicalAddress] = useState<DevicePhysicalAddress>({
    addressLine1: deviceInfo.details.physicalAddress.addressLine1 || '',
    addressLine2: deviceInfo.details.physicalAddress.addressLine2 || '',
    city: deviceInfo.details.physicalAddress.city || '',
    postcode: deviceInfo.details.physicalAddress.postcode || '',
    state: deviceInfo.details.physicalAddress.state || '',
    country: deviceInfo.details.physicalAddress.country || '',
    number: deviceInfo.details.physicalAddress.number || '',
    floor: deviceInfo.details.physicalAddress.number || ''
  })

  const [deviceCustom, setDeviceCustom] = useState<DeviceCustom[]>([
    {
      key: '',
      value: ''
    }
  ])

  const initialValues: any = {
    ...deviceMain,
    ...deviceDetails,
    ...deviceLocation,
    ...devicePhysicalAddress,
    ...deviceCustom
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row align={'middle'}>
                <Col span={12}>
                  <Breadcrumbs title={t('Device')} breadcrumbItems={t('Edit Device')} />
                </Col>
                <Col span={12}>
                  <Button
                    style={{ float: 'right' }}
                    type="button"
                    color={'primary'}
                    className="btn-rounded waves-effect waves-light mb-2 me-2 "
                  >
                    <Link to={`/device/${address}`}>
                      <span className={styles.textButtonAdd}>
                        <ArrowLeftOutlined className={styles.iconStyle} />
                        <span>{t('Go Back')}</span>
                      </span>
                    </Link>
                  </Button>
                </Col>
              </Row>

              <Formik
                initialValues={initialValues}
                onSubmit={(values: CreateDevice, { setSubmitting, resetForm }: FormikHelpers<CreateDevice>) => {
                  setIsLoading(true)
                  dispatch(
                    editDevices(
                      history,
                      supplierAddress,
                      address,
                      parseForm(deviceMain, deviceLocation, deviceDetails, deviceCustom, devicePhysicalAddress)
                    )
                  )
                  setIsLoading(true)
                  setSubmitting(false)
                  resetForm()
                }}
              >
                <Form>
                  <Row>
                    <Col span={12}>
                      <AddDeviceFormMain deviceMain={deviceMain} setDeviceMain={setDeviceMain} />
                    </Col>

                    <Col span={12}>
                      <AddDeviceFormLocation deviceLocation={deviceLocation} setDeviceLocation={setDeviceLocation} />
                    </Col>
                  </Row>

                  <Row className={'mt-2'}>
                    <Col span={12}>
                      <AddDeviceFormDetails deviceDetails={deviceDetails} setDeviceDetails={setDeviceDetails} />
                    </Col>
                    <Col span={12}>
                      <AddDeviceFormAddress
                        setDevicePhysicalAddress={setDevicePhysicalAddress}
                        devicePhysicalAddress={devicePhysicalAddress}
                      />
                    </Col>
                  </Row>

                  <Row className={'mt-2'} align={'middle'} justify={'center'}>
                    <Col span={24}>
                      <AddDeviceFormCustom deviceCustom={deviceCustom} setDeviceCustom={setDeviceCustom} />
                    </Col>
                  </Row>

                  <Button
                    block={isLoading}
                    type="submit"
                    color="success"
                    className="btn-rounded waves-effect waves-light"
                  >
                    {t('Save change')}
                  </Button>
                </Form>
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}
