import React, { useState } from 'react'
import { CardBody } from 'reactstrap'
import { Form, Input } from 'antd'
import '../../App.css'
import { InfoCircleOutlined } from '@ant-design/icons'
import styles from './Dashboard.module.css'
import { useTranslation } from 'react-i18next'

interface PropsI {
  handleClick: (value: string) => void
}

export const DashBoardDeviceMapInfo: React.FC<PropsI> = ({ handleClick }) => {
  const { t } = useTranslation()
  const [searchAddress, setSearchAddress] = useState('')
  const [form] = Form.useForm()
  const validateMessages = {
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      regexp: '${label} is not a valid address'
    }
  }

  return (
    <CardBody className={styles.dashboardCardWrapper}>
      <Form form={form} validateMessages={validateMessages}>
        <Form.Item
          name={['Device owner', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t('Device address')}
          tooltip={{ title: t('Device address'), icon: <InfoCircleOutlined /> }}
          className={styles.dashBoardForm}
          rules={[
            {
              message: t('Please input correct address!'),
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <Input.Search
            value={searchAddress}
            className={'ant-input-search-button'}
            name={'search'}
            placeholder={t('Search device')}
            enterButton={true}
            onChange={(e) => {
              setSearchAddress(e.target.value)
            }}
            onSearch={(value) => {
              handleClick(searchAddress)
            }}
          />
        </Form.Item>
      </Form>
    </CardBody>
  )
}
