/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { KeysListMainContainer } from '../../Keys/KeysList/KeysListMainContainer'
import { useLazyQuery } from '@apollo/client'
import { GetKeysForDevice } from '../../../../query/Keys'
import { KeysListType } from '../../../../Redux/typeReducer/KeysType'
import { ParamsKeys } from '../../../../query/query'
import { KeyStatistics } from '../../Keys/KeysContainer'
import { GetDeviceStatistics } from '../../../../query/Device'
interface PropsI {
  address: string
}

export const DeviceKeysOverview: React.FC<PropsI> = ({ address }) => {
  const [deviceKeysInformation, setDeviceKeysInformation] = useState<KeysListType[]>([])
  const [paramsKeys, setParamsKeys] = useState<ParamsKeys>({
    booleanFilters: {
      burned: false
    },
    keysLoading: false,
    stringFilters: {
      issuer: null,
      owner: null,
      device: null
    },
    pagination: {
      take: 10,
      total: 10,
      skip: 0
    },
    search: null,
    sort: {
      order: 'asc' || 'desc',
      orderBy: ''
    }
  })
  const [keyStatistic, setKeyStatistic] = useState<KeyStatistics>()
  const [keysForDevice] = useLazyQuery(GetKeysForDevice, {
    variables: {
      burned: paramsKeys.booleanFilters.burned ?? false,
      issuer: paramsKeys.stringFilters.issuer ?? null,
      owner: paramsKeys.stringFilters.owner ?? null,
      device: paramsKeys.stringFilters.device ?? address,
      search: paramsKeys.search ?? null,
      order: paramsKeys.sort.order ?? 'desc',
      orderBy: paramsKeys.sort.orderBy ?? 'createdAt',
      skip: 0,
      take: 10
    },
    onCompleted: (data) => {
      const dataKeys = data.keys.objects.map((e: KeysListType) => ({
        ...e,
        key: e.assetId
      }))
      setDeviceKeysInformation(dataKeys)
      setParamsKeys({ ...paramsKeys, pagination: { ...data.keys.meta } })
    }
  })
  
  const [getKeysStatistic] = useLazyQuery(GetDeviceStatistics, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: address
    },
    onCompleted: (data) => {
      setKeyStatistic(data.deviceStats.current.data.keys)
    },
    onError: (error) => {
      console.log(error)
    }
  })

  useEffect(() => {
    getKeysStatistic()
  }, [])

  useEffect(() => {
    keysForDevice()
  }, [])

  return (
    <KeysListMainContainer
      keysStatistic={keyStatistic!}
      deviceGenerateKey={address}
      setParamsKeys={setParamsKeys}
      paramsKeys={paramsKeys}
      loading={paramsKeys.keysLoading}
      data={deviceKeysInformation}
    />
  )
}
