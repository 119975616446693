import React, { useEffect, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'
import profile1 from '../../../../assets/images/profile-img.png'
import { useDispatch } from 'react-redux'
import { ConfigList } from './ConfigList'
import { ChangeFormScreen } from './ChangeSetupForm'
import { getConfigInfo } from '../../../../Redux/Auth/AuthReducer'

const ContactsProfile: React.FC = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [configModal, setConfig] = useState(false)
  const [loading, setLoading] = useState(true)

  const togConfig = () => {
    setConfig(!configModal)
    setLoading(!loading)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add('no_padding')
  }

  useEffect(() => {
    dispatch(getConfigInfo(history))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row style={{ marginTop: '1.5rem', display: 'flex', justifyContent: 'center' }}>
            <Col xl="6">
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs="5">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>It will seem like simplified</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <ConfigList
                    showChangeConfigModal={() => {
                      togConfig()
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {configModal && <ChangeFormScreen togModal={togConfig} configModal={configModal} setConfigModal={setConfig} />}
    </>
  )
}

export default withRouter(ContactsProfile)
