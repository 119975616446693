import React from 'react'
import { Select } from 'antd'

const { Option } = Select

interface PropsStatus {
  placeHolderName: string
  activeStatus: string
  disabledStatus: string
  handleChange: (value: string) => void
}

export const DeviceStatus: React.FC<PropsStatus> = ({
  placeHolderName,
  activeStatus,
  disabledStatus,
  handleChange
}) => {
  return (
    <div>
      <Select
        onChange={(value: string) => {
          handleChange(value)
        }}
        style={{ width: '150px' }}
        placeholder={placeHolderName}
        optionFilterProp="children"
      >
        <Option value={'null'}>None</Option>
        <Option value={'true'}>{activeStatus}</Option>
        <Option value={'false'}>{disabledStatus}</Option>
      </Select>
    </div>
  )
}
