import React, { useState } from 'react'
import { Button, Form, Input, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface PropsI {
  handlerValueIssuer: (value: string) => void
  handlerValueOwner: (value: string) => void
  handlerValueDevice: (value: string) => void
}

export const DropdownKeyMenu: React.FC<PropsI> = ({ handlerValueIssuer, handlerValueOwner, handlerValueDevice }) => {
  const {t} = useTranslation()
  const [listsAddress, setListsAddress] = useState({
    issuer: '',
    owner: '',
    device: ''
  })

  const validateMessages = {
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} is required!',
    types: {
      // eslint-disable-next-line no-template-curly-in-string
      regexp: '${label} is not a valid address'
    }
  }

  return (
    <Space style={{ background: '#fff', padding: '1rem', display: 'block' }}>
      <Form validateMessages={validateMessages}>
        <Form.Item
          name={['Issuer', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t('Issuer address')}
          tooltip={{ title: 'Issuer address', icon: <InfoCircleOutlined /> }}
          style={{ width: '100%' }}
          rules={[
            {
              message: t('Please input correct address!') ,
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <div style={{ display: 'flex' }}>
            <Input
              style={{ width: '100%' }}
              placeholder={t('Issuer address')}
              value={listsAddress.issuer}
              onChange={(e) => setListsAddress({ ...listsAddress, issuer: e.target.value })}
            />
            <Button onClick={() => handlerValueIssuer(listsAddress.issuer)}>{t('Check')}</Button>
          </div>
        </Form.Item>
        <Form.Item
          name={['Owner', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label={t('Owner')}
          tooltip={{ title: 'Owner address', icon: <InfoCircleOutlined /> }}
          rules={[
            {
              message: t('Please input correct address!'),
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <div style={{ display: 'flex' }}>
            <Input
              value={listsAddress.owner}
              placeholder={'Owner'}
              onChange={(e) => setListsAddress({ ...listsAddress, owner: e.target.value })}
            />
            <Button onClick={() => handlerValueOwner(listsAddress.owner)}>{t('Check')}</Button>
          </div>
        </Form.Item>
        <Form.Item
          name={['Device', 'address']}
          validateFirst={true}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label="Device address"
          tooltip={{ title: 'Device address', icon: <InfoCircleOutlined /> }}
          style={{ width: '100%' }}
          rules={[
            {
              message: t('Please input correct address!'),
              pattern: new RegExp(/^[1-9A-HJ-NP-Za-km-z]{35}$/)
            }
          ]}
        >
          <div style={{ display: 'flex' }}>
            <Input
              value={listsAddress.device}
              placeholder={t('Device address')}
              onChange={(e) => setListsAddress({ ...listsAddress, device: e.target.value })}
            />
            <Button onClick={() => handlerValueDevice(listsAddress.device)}>{t('Check')}</Button>
          </div>
        </Form.Item>
      </Form>
    </Space>
  )
}
