import React from 'react'
import { Link } from 'react-router-dom'
import LanguageDropdown from './TopbarDropdown/LanguageDropdown'
import logo from '../../assets/image/sManagerLogo.svg'
import ProfileMenu from './TopbarDropdown/ProfileMenu'
import styles from './Layout.module.css'
interface PropsI {
  leftSideBarType: string
  leftMenu: boolean
}

const Header: React.FC<PropsI> = ({ leftSideBarType, leftMenu }) => {
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className={styles.headerWrapper}>
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span>
                  <img src={logo} alt="" height="45" className={styles.imageHeaderWrapper} />
                </span>
              </Link>
            </div>
          </div>

          <div className="d-flex">
            <LanguageDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default Header
