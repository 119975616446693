import React, { Dispatch, SetStateAction } from 'react'
import classes from '../Device.module.css'

import { FormItem, Input } from 'formik-antd'
import { Col, Row } from 'antd'
import { Button, Card, CardBody } from 'reactstrap'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { DeviceCustom } from '../type/DeviceType'
import { useTranslation } from 'react-i18next'

interface PropsI {
  deviceCustom: Array<DeviceCustom>
  setDeviceCustom: Dispatch<SetStateAction<DeviceCustom[]>>
}

export const AddDeviceFormCustom: React.FC<PropsI> = ({ deviceCustom, setDeviceCustom }) => {
  const {t} = useTranslation()
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, i: string | number, type: 'key' | 'value') => {
    setDeviceCustom(
      deviceCustom.map((entry, index) => {
        if (index !== i) return entry

        return { ...entry, [type]: e.target.value }
      })
    )
  }

  const handleRemoveClick = (index: number) => {
    const customList = [...deviceCustom]
    customList.splice(index, 1)
    setDeviceCustom(customList)
  }

  const handleAddClick = () => {
    setDeviceCustom([...deviceCustom, { key: '', value: '' }])
  }

  return (
    <>
      <Card>
        <CardBody>
          <h3>{t('Custom')}</h3>
          <Row align={'middle'} justify={'center'}>
            <Col span={24} style={{ alignItems: 'center', justifyContent: 'center' }}>
              {deviceCustom.map((x, i) => {
                return (
                  <Row key={i}>
                    <Col span={6}>
                      <FormItem label={'Key'} className={classes.defaultFormItem} name={'Key'}>
                        <Input
                          onChange={(e) => handleInputChange(e, i, 'key')}
                          value={x.key}
                          name={'key'}
                          placeholder={'key'}
                        />
                      </FormItem>
                    </Col>

                    <Col span={6} style={{ marginLeft: '0.5rem' }}>
                      <FormItem label={'Value'} className={classes.defaultFormItem} name={'Value'}>
                        <Input
                          onChange={(e) => handleInputChange(e, i, 'value')}
                          value={x.value}
                          name={'value'}
                          placeholder={'value'}
                        />
                      </FormItem>
                    </Col>

                    <Col span={2}>
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '1rem'
                        }}
                      >
                        {deviceCustom.length - 1 === i && (
                          <Button
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                            type="button"
                            color="primary"
                            className="btn-rounded waves-effect waves-light mb-2 me-2"
                            onClick={handleAddClick}
                          >
                            <PlusOutlined />
                          </Button>
                        )}
                        {deviceCustom.length !== 1 && (
                          <Button
                            style={{
                              alignItems: 'center',
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                            type="button"
                            color="primary"
                            className="btn-rounded waves-effect waves-light mb-2 me-2"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <MinusOutlined />
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                )
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
